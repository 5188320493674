import {
  Component,
  EventEmitter,
  Output,
  OnInit,
  inject,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared/shared.module';
import {
  FinancialAdvisorService,
  UserService
} from '@app/data/amn-api/services';
import { first, Subscription } from 'rxjs';
import { ROLE_FINANCIAL_ADVISOR } from '@app/data/models';
import { AlertsService } from '@app/core/services';
import { ErrorService } from '@app/core/services/error.service';
import { SelectItem } from 'primeng/api';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { AmnSingleFileUploadComponent } from '@app/shared/components';
import { CustomerDto, UserDto } from '@app/data/amn-api/models';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteSelectEvent
} from 'primeng/autocomplete';

@Component({
  standalone: true,
  selector: 'app-add-financial-advisor',
  imports: [PrimeNgModule, TranslateModule, SharedModule],
  templateUrl: './add-financial-advisor.component.html',
  styleUrls: ['./add-financial-advisor.component.scss']
})
export class AddFinancialAdvisorComponent implements OnInit, OnDestroy {
  private fb = inject(FormBuilder);
  private financialAdvisorService = inject(FinancialAdvisorService);
  private alertsService = inject(AlertsService);
  private subscription = new Subscription();
  private errorService = inject(ErrorService);
  private userService = inject(UserService);
  /* eslint-disable @typescript-eslint/no-explicit-any */
  private debounceTimeout!: any;

  @ViewChild('amnFileUploader') fileUploader!: AmnSingleFileUploadComponent;
  @Output() addFinancialAdvisor = new EventEmitter<void>();

  visible: boolean = false;
  users: UserDto[] = [];
  filteredUsers: UserDto[] = [];
  selectedUser: UserDto | null = null;
  financialAdvisorForm!: FormGroup;
  officesOpts: SelectItem[] = [{ label: '', value: '' }];
  selectedFile!: Blob | null;

  ngOnInit(): void {
    this.loadUsers();
    this.selectedFile = null;
    this.financialAdvisorForm = this.fb.group({
      name: new FormControl<string>('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      lastName: new FormControl<string>('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      email: new FormControl<string>('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(50)
      ]),
      phoneNumber: new FormControl<string>('', [
        Validators.required,
        Validators.maxLength(20)
      ]),
      commission: new FormControl<string>('', [
        Validators.required,
        Validators.maxLength(20)
      ]),
      marketing: new FormControl<string>('', [
        Validators.required,
        Validators.maxLength(20)
      ]),
      officeId: new FormControl<number | null>(null, [
        Validators.required,
        Validators.maxLength(20)
      ])
    });
    this.getOffices();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onAddClick(): void {
    this.visible = true;
    this.financialAdvisorForm.reset();
    this.selectedUser = null;
  }

  onCancelClick(): void {
    this.visible = false;
    this.fileUploader.clearFileUpload();
    this.financialAdvisorForm.reset();
    this.selectedUser = null;
    this.clearFormValues();
  }

  handleSetOffice($event: DropdownChangeEvent) {
    if ($event.value) {
      this.financialAdvisorForm.get('officeId')?.setValue(String($event.value));
    }
  }

  getOffices() {
    this.subscription.add(
      this.financialAdvisorService.getOfficesAsync().subscribe({
        next: (response) => {
          if (response.data?.length) {
            this.officesOpts = [];
            response.data?.forEach((office) => {
              this.officesOpts.push({
                label: office.name ?? 'N/A',
                value: String(office.id) ?? '1'
              });
            });
          }
        }
      })
    );
  }

  onUpload(file: Blob | null) {
    if (file !== null) {
      this.selectedFile = file;
    } else {
      this.selectedFile = null;
    }
  }

  loadUsers(searchText?: string): void {
    this.userService
      .searchUsersAsync({
        SearchText: searchText || undefined,
        Top: 60,
        Skip: undefined,
        SortBy: undefined,
        SortDescending: undefined,
        ShowInactives: true
      })
      .pipe(first())
      .subscribe({
        next: (value) => {
          this.users = value.data ?? [];
          if (searchText) {
            this.filteredUsers = [...this.users];
          }
        },
        error: (error: unknown) => {
          console.error('Error al cargar los usuarios', error);
        }
      });
  }

  onUserSelect(event: AutoCompleteSelectEvent): void {
    const user = event.value as CustomerDto;

    if (user) {
      this.financialAdvisorForm.patchValue({
        name: user.name,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber
      });
    }
  }
  clearFormValues(): void {
    this.selectedUser = null;

    this.financialAdvisorForm.patchValue({
      name: '',
      lastName: '',
      email: '',
      phoneNumber: ''
    });
    this.loadUsers();
  }

  filterUsers(event: AutoCompleteCompleteEvent): void {
    const query = event.query.toLowerCase();

    this.filteredUsers = this.users.filter((user) =>
      user.email?.toLowerCase().includes(query)
    );

    if (this.filteredUsers.length === 0) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      this.debounceTimeout = setTimeout(() => {
        this.loadUsers(query);
      }, 500);
    }

    if (this.filteredUsers.length === 0) {
      this.financialAdvisorForm.patchValue({
        email: query,
        name: '',
        lastName: '',
        phoneNumber: ''
      });
    }
  }

  onSubmit(): void {
    if (this.financialAdvisorForm.valid) {
      const newAdvisorInfo = {
        ...this.financialAdvisorForm.value,
        photo: this.selectedFile,
        roles: [ROLE_FINANCIAL_ADVISOR]
      };

      this.subscription.add(
        this.financialAdvisorService
          .createFinancialAdvisorAsync({ body: newAdvisorInfo })
          .subscribe({
            next: () => {
              this.alertsService.addSuccess(
                'Alerts.FinancialAdvisorAdded',
                'Alerts.FinancialAdvisorAddedCorrectly',
                true
              );
              this.onCancelClick();
              this.addFinancialAdvisor.emit();
              this.selectedUser = null;
            },
            error: (error: unknown) => {
              this.errorService.handleError(error, {
                badRequest: 'Alerts.ErrorCreatingFinancialAdvisor'
              });
            }
          })
      );
    }
  }
}
