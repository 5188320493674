<div class="col-12 xl:col-12 pt-0 mt-0">
  <div class="card">
    <div class="flex align-items-center justify-content-between">
      <span class="text-900 text-xl font-semibold">{{
        'Dashboard.DailyGains' | translate
      }}</span>
      <div class="flex gap-3 sm:ml-auto">
        <p-multiSelect
          [options]="accountDropdownOptions"
          [(ngModel)]="selectedAccountIds"
          placeholder="{{ 'Dashboard.SelectAccount' | translate }}"
          (onChange)="onAccountsChange()"
          [display]="'chip'"
          [filter]="false"
          [showToggleAll]="false"
          [showClear]="false"
          [showHeader]="false">
        </p-multiSelect>
        <p-calendar
          [(ngModel)]="rangeDates"
          placeholder="__/__/____ - __/__/____"
          selectionMode="range"
          [readonlyInput]="true"
          (onSelect)="onDateRangeChange()">
        </p-calendar>
      </div>
    </div>
    <p-chart
      type="line"
      [data]="lineData"
      [options]="lineOptions"
      height="300px"
      [plugins]="plugins">
    </p-chart>
  </div>
</div>
