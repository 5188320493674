<div class="grid">
  <div class="col-12">
    <app-total-investments
      [customersTotals]="customersTotals"></app-total-investments>
  </div>
  @if (this.canViewDailyGains) {
    <div class="col-12">
      <app-dashboard-chart-daily-gains></app-dashboard-chart-daily-gains>
    </div>
  }
</div>
