<h3>{{ 'Menu.Playground' | translate }}</h3>

<div class="grid">
  <div class="col-12 md:col-6">
    <!-- Alerts -->
    <div class="card">
      <h4>{{ 'Playground.Alerts' | translate }}</h4>

      <div class="flex flex-wrap gap-2">
        <p-button
          severity="success"
          label="Success"
          (onClick)="showSuccess()"></p-button>
        <p-button
          severity="help"
          label="Information"
          (onClick)="showInfo()"></p-button>
        <p-button
          severity="warning"
          label="Warning"
          (onClick)="showWarn()"></p-button>
        <p-button
          severity="danger"
          label="Error"
          (onClick)="showError()"></p-button>
      </div>
    </div>

    <!-- Health Checks -->
    <div class="card">
      <h4>{{ 'Playground.HealthChecks' | translate }}</h4>

      <div class="flex flex-wrap gap-2">
        <p-button
          severity="info"
          label="Check Api"
          (onClick)="checkApi()"></p-button>
        <p-button
          severity="secondary"
          label="Get users"
          (onClick)="getUsers()"></p-button>
      </div>
    </div>
  </div>

  <div class="col-12 md:col-6">
    <!-- Avatars -->
    <div class="card">
      <h4>Avatars</h4>

      <div class="flex flex-wrap gap-2">
        <app-avatar displayName="Obi Wan" size="normal"></app-avatar>
        <app-avatar
          displayName="Darth Vader"
          size="xlarge"
          shape="circle"></app-avatar>
        <app-avatar
          displayName="Link"
          size="large"
          image="https://primefaces.org/cdn/primeng/images/demo/avatar/onyamalimba.png"></app-avatar>

        <p-avatar label="J" size="xlarge"></p-avatar>
        <p-avatar
          ize="xlarge"
          shape="circle"
          image="https://primefaces.org/cdn/primeng/images/demo/avatar/onyamalimba.png"></p-avatar>
      </div>
    </div>

    <!-- Clocks -->
    <div class="card">
      <h4>{{ 'Playground.Clocks' | translate }}</h4>

      <div class="flex flex-wrap gap-2">
        <p-button
          severity="success"
          label="Clock (first)"
          (onClick)="subscribeFirst()"></p-button>
        <p-button
          severity="warning"
          label="Clock (module)"
          (onClick)="subscribeModule()"></p-button>
        <p-button
          severity="danger"
          label="Clock (all)"
          (onClick)="subscribe()"></p-button>
      </div>
    </div>
  </div>

  <div class="col-12">
    <!-- Error Handling -->
    <div class="card p-fluid">
      <h4>{{ 'Playground.ErrorHandling' | translate }}</h4>

      <div class="field grid align-items-center">
        <div class="col-12 mb-2 md:col-2 md:mb-0 font-bold">
          {{ 'Playground.Exceptions' | translate }}
        </div>

        <div class="col-12 mb-2 md:col-5 md:mb-0">
          <div class="formgroup-inline">
            <div class="field mb-0 w-5">
              <p-dropdown
                [options]="exceptionTypes"
                [(ngModel)]="exceptionType"
                placeholder="{{
                  'Playground.SelectTypePlaceholder' | translate
                }}"></p-dropdown>
            </div>
          </div>
        </div>
        <div class="col-12 mb-2 md:col-2 md:mb-0">
          <p-button
            id="checkExceptions"
            severity="danger"
            label="{{ 'Playground.CheckException' | translate }}"
            (onClick)="checkExceptions()"></p-button>
        </div>
      </div>

      <div class="field grid align-items-center">
        <div class="col-12 mb-2 md:col-2 md:mb-0 font-bold pt-2">
          {{ 'Playground.Errors' | translate }}
        </div>

        <div class="col-12 mb-2 md:col-5 md:mb-0">
          <div class="formgroup-inline">
            <div class="field field-checkbox mb-0 pt-2">
              <label for="multipleErrors">Multiple</label>
              <p-inputSwitch id="multipleErrors" [(ngModel)]="multipleErrors" />
            </div>
          </div>
        </div>
        <div class="col-12 mb-2 md:col-2 md:mb-0">
          <p-button
            id="checkErrors"
            severity="warning"
            label="{{ 'Playground.CheckErrors' | translate }}"
            (onClick)="checkErrors()"></p-button>
        </div>
      </div>

      <div class="field grid align-items-center">
        <div class="col-12 mb-2 md:col-2 md:mb-0 font-bold">
          {{ 'Playground.Validations' | translate }}
        </div>
        <div class="col-12 mb-2 md:col-5 md:mb-0">
          <div class="formgroup-inline">
            <div class="field mb-0 w-3">
              <p-inputNumber
                id="validationNumber"
                [showButtons]="true"
                buttonLayout="horizontal"
                inputId="horizontal"
                spinnerMode="horizontal"
                [min]="0"
                [max]="2000"
                [step]="10"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
                [(ngModel)]="validationNumber"></p-inputNumber>
            </div>
            <div class="field field-checkbox mb-0 pt-2">
              <label for="validationFlag">Flag</label>
              <p-inputSwitch id="validationFlag" [(ngModel)]="validationFlag" />
            </div>
            <div class="field mb-0">
              <input
                type="text"
                pInputText
                id="validationMessage"
                placeholder="{{ 'Playground.MessagePlaceholder' | translate }}"
                [(ngModel)]="validationMessage" />
            </div>
          </div>
        </div>
        <div class="col-12 mb-2 md:col-2 md:mb-0">
          <p-button
            id="checkValidations"
            severity="warning"
            label="{{ 'Playground.CheckValidations' | translate }}"
            (onClick)="checkValidations()"></p-button>
        </div>
      </div>
    </div>
  </div>
</div>
