<form (ngSubmit)="onSubmit()" #customerForm="ngForm" [formGroup]="contractForm">
  <div class="grid formgrid p-fluid">
    <div class="field col-12 md:col-4 xl:col-3">
      <app-amn-input-mask
        id="contractNumber"
        label="{{ 'Contracts.ContractNumber' | translate }}"
        mask="AMN - ***? *"
        formControlName="contractNumber"
        name="contractNumber"
        [errorMessages]="{
          required: 'Contract number is required',
          pattern:
            'Invalid format. Use 3 uppercased letters/numbers optionally followed by 1 uppercased letter/number.'
        }"
        placeholder="AMN - ***? *">
      </app-amn-input-mask>
    </div>
    <div class="field col-12 md:col-3 xl:col-3">
      <app-amn-input-money
        id="OriginalContractAmount"
        label="{{ 'Contracts.OriginalContractAmount' | translate }}"
        formControlName="amount"
        [errorMessages]="{
          required: 'Amount is required.',
          min: 'the amount must be great than 3000'
        }">
      </app-amn-input-money>
    </div>
    <div class="field col-12 md:col-2 xl:col-2">
      <label for="currency" class="font-medium text-900">{{
        'Banks.Currency' | translate
      }}</label>
      <p-dropdown
        id="currency"
        [options]="currencyOptions"
        optionValue="value"
        optionLabel="label"
        formControlName="currency"
        placeholder="Select currency"
        appendTo="body">
      </p-dropdown>
    </div>
    <div class="field col-12 md:col-6 xl:col-4">
      <app-amn-input-date
        id="signatureDate"
        label="{{ 'Contracts.SignatureDate' | translate }}"
        formControlName="signDate"
        [showTime]="true"
        view="date">
      </app-amn-input-date>
    </div>
    <div class="field col-6 md:col-6 xl:col-4">
      <app-amn-input-date
        id="initialDate"
        label="{{ 'Contracts.InitialDate' | translate }}"
        formControlName="startDate"
        view="month"
        [errorMessages]="{ required: 'Initial date is required' }">
      </app-amn-input-date>
    </div>
    <div class="field col-6 md:col-6 xl:col-3">
      <app-amn-input-date
        id="finalDate"
        label="{{ 'Contracts.FinalDate' | translate }}"
        formControlName="endDate"
        view="date"
        [errorMessages]="{ required: 'Final date is required.' }">
      </app-amn-input-date>
    </div>
    <div class="field col-6 md:col-6 xl:col-3">
      <label for="interestType" class="font-medium text-900">{{
        'Contracts.TypeOfInterest' | translate
      }}</label>
      <p-dropdown
        id="interestType"
        [options]="interestOptions"
        optionValue="value"
        optionLabel="label"
        formControlName="interestType"
        placeholder="Select Interest Type"
        appendTo="body">
      </p-dropdown>
    </div>
    <div class="field col-6 md:col-6 xl:col-3">
      <app-amn-input-percent
        id="percentage"
        prefix="%"
        label="{{ 'Contracts.Percentage' | translate }}"
        formControlName="interestRate"
        [errorMessages]="{
          required: 'Percentage is required.',
          max: 'Max 100',
          min: 'Min 0'
        }">
      </app-amn-input-percent>
    </div>
    <div class="field col-6 md:col-6 xl:col-3">
      <label for="company" class="font-medium text-900">{{
        'Contracts.Company' | translate
      }}</label>
      <p-dropdown
        id="company"
        [options]="companyOptions"
        optionValue="value"
        optionLabel="label"
        formControlName="company"
        placeholder="Select Company"
        appendTo="body">
      </p-dropdown>
    </div>
    <div class="field col-12 md:col-3 xl:col-3">
      <app-amn-input-money
        id="InvestedAmount"
        label="{{ 'Contracts.InvestedAmount' | translate }}"
        formControlName="investmentAmount">
      </app-amn-input-money>
    </div>
    <div class="field col-2 mt-4">
      @if (showReinvestSwitch) {
        <div class="flex align-items-center justify-content-between">
          <label class="font-medium text-900" for="reinvestSwitch">{{
            'Contracts.Reinvest' | translate
          }}</label>
          <p-inputSwitch
            id="reinvestSwitch"
            formControlName="reInvest"
            [disabled]="!canEditReinvestSwitch"></p-inputSwitch>
        </div>
      }
    </div>
    <div class="w-full flex justify-content-end flex-wrap">
      <p-button
        label="{{ 'Common.Cancel' | translate }}"
        icon="pi pi-times"
        class="w-auto mr-3 mt-3"
        [outlined]="true"
        severity="secondary"
        (click)="onCancelClick()"
        [style.display]="this.isEditing ? 'block' : 'none'"></p-button>
      <p-button
        type="submit"
        label="{{ 'Common.Save' | translate }}"
        icon="pi pi-check"
        class="w-auto mt-3 mr-3"
        [outlined]="true"
        severity="primary"
        [disabled]="!this.contractForm.valid"
        [style.display]="isEditing ? 'block' : 'none'"></p-button>
      @if (showReinvestSwitch && !contractDetails.reInvest) {
        <p-button
          label="{{ 'Contracts.Reinvest' | translate }}"
          icon="pi pi-angle-double-right"
          class="w-auto mr-3 mt-3"
          (click)="onReinvestClick()"></p-button>
      }
      <p-button
        label="{{ 'Common.Edit' | translate }}"
        icon="pi pi-user-edit"
        class="w-auto mt-3"
        (click)="onEditClick()"
        [style.display]="
          this.isEditing || !this.isEditable ? 'none' : 'block'
        "></p-button>
    </div>
  </div>
</form>
