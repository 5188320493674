import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AlertsService, ProfileService } from '@app/core/services';
import { firstValueFrom } from 'rxjs';

export const RoleGuard: CanActivateFn = async (route) => {
  const alertsService = inject(AlertsService);
  const profileService = inject(ProfileService);
  const allowedRoles = route.data?.['allowedRoles'] as number[];

  const denyAccess = () => {
    alertsService.addWarning(
      'Alerts.AccessDenied',
      'Alerts.AccessDeniedDescription',
      true
    );
    return false;
  };

  if (!profileService.roles || profileService.roles.length === 0) {
    try {
      await firstValueFrom(profileService.userLoaded);
    } catch (error) {
      console.error('Error al cargar roles:', error);
      return denyAccess();
    }
  }

  return profileService.hasRole(allowedRoles) ? true : denyAccess();
};
