/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AlertsService } from '@app/core/services';
import { ErrorService } from '@app/core/services/error.service';
import {
  MyFxBookAccountDto,
  MyFxBookAccountDtoSearchResponse,
  MyFxBookDailyGainsGroupedByAccountDto,
  MyFxBookDailyGainsGroupedByAccountDtoSearchResponse
} from '@app/data/amn-api/models';
import { MyFxBookService } from '@app/data/amn-api/services';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { first } from 'rxjs';

Chart.register(ChartDataLabels);

@Component({
  selector: 'app-dashboard-chart-daily-gains',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    PrimeNgModule,
    FormsModule,
    SharedModule
  ],
  providers: [CurrencyPipe, DatePipe],
  templateUrl: './dashboard-chart-daily-gains.component.html',
  styleUrls: ['./dashboard-chart-daily-gains.component.scss']
})
export class DashboardChartDailyGainsComponent implements OnInit {
  rangeDates: Date[] | undefined;
  lineData: any;
  lineOptions: any;
  startDate: string = '';
  endDate: string = '';
  selectedAccountIds: number[] = [];
  searchCriteria: string = '';
  accounts!: MyFxBookAccountDto[];
  accountDropdownOptions: { label: string; value: number }[] = [];
  plugins = [ChartDataLabels];

  constructor(
    private myFxBookService: MyFxBookService,
    public translateService: TranslateService,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private alertsService: AlertsService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    const today = new Date();
    const fifteenDaysAgo = new Date();

    fifteenDaysAgo.setDate(today.getDate() - 15);

    this.rangeDates = [fifteenDaysAgo, today];
    this.startDate = fifteenDaysAgo.toISOString().split('T')[0];
    this.endDate = today.toISOString().split('T')[0];

    this.fetchDailyGainsData();
    this.setupChartOptions();
    this.GetAccountData();
  }

  fetchDailyGainsData() {
    this.myFxBookService
      .searchMyFxBookDailyGains({
        StartDate: this.startDate,
        EndDate: this.endDate,
        AccountIds:
          this.selectedAccountIds.length > 0
            ? this.selectedAccountIds
            : undefined
      })
      .subscribe(
        (response: MyFxBookDailyGainsGroupedByAccountDtoSearchResponse) => {
          if (response && response.data) {
            this.updateChartData(response.data);
          }
        }
      );
  }

  GetAccountData() {
    this.myFxBookService
      .searchMyFxBookAccounts({
        SearchText: this.searchCriteria
      })
      .pipe(first())
      .subscribe({
        next: (value: MyFxBookAccountDtoSearchResponse) => {
          if (!value.data) {
            this.alertsService.addWarning(
              'Alerts.AccountsNotFound',
              'Alerts.AccountsNotFoundDesc',
              true
            );
          } else {
            this.accounts = value.data;
            this.accountDropdownOptions = this.accounts
              .filter((account) => account.name && account.id !== undefined)
              .map((account) => ({
                label: account.name ?? 'Unknown Account',
                value: account.id as number
              }));
          }
        },
        error: (error: unknown) => {
          this.errorService.handleError(error);
        }
      });
  }

  updateChartData(data: MyFxBookDailyGainsGroupedByAccountDto[]) {
    const uniqueDates = this.getUniqueDates(data);

    this.lineData = {
      labels: uniqueDates,
      datasets: data.map((group) => {
        const randomColor = this.getRandomColor();

        return {
          label: group.name,
          fill: false,
          borderColor: randomColor,
          backgroundColor: randomColor,
          pointBackgroundColor: randomColor,
          yAxisID: 'y',
          tension: 0.4,
          data: uniqueDates.map((date) => {
            const gain = (group.dailyGains ?? []).find((g) => {
              if (g.date) {
                try {
                  const [day, month, year] = g.date.split(' ')[0].split('/');
                  const validDate = new Date(+year, +month - 1, +day);
                  const formattedDate = this.datePipe.transform(
                    validDate,
                    'dd/MM/yyyy'
                  );
                  return formattedDate === date;
                } catch (error) {
                  console.error('Error procesando la fecha:', g.date, error);
                  return false;
                }
              }
              return false;
            });

            return gain ? gain.profitUSD : 0;
          })
        };
      })
    };

    this.lineData = { ...this.lineData };
  }

  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getAllDatesInRange(): string[] {
    if (!this.rangeDates || !this.rangeDates[0] || !this.rangeDates[1]) {
      return [];
    }

    const startDate = new Date(this.rangeDates[0]);
    const endDate = new Date(this.rangeDates[1]);
    const dates: string[] = [];

    for (
      let dt = new Date(startDate);
      dt <= endDate;
      dt.setDate(dt.getDate() + 1)
    ) {
      dates.push(dt.toISOString().split('T')[0]);
    }

    return dates;
  }

  onAccountsChange() {
    this.fetchDailyGainsData();
  }

  onDateRangeChange() {
    if (this.rangeDates && this.rangeDates[0] && this.rangeDates[1]) {
      this.startDate = this.rangeDates[0].toISOString().split('T')[0];
      this.endDate = this.rangeDates[1].toISOString().split('T')[0];
      this.fetchDailyGainsData();
    } else {
      console.warn('Seleccione un rango de fechas completo.');
    }
  }

  setupChartOptions() {
    this.lineOptions = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 20
        }
      },
      scales: {
        x: {
          type: 'category',
          beginAtZero: true,
          ticks: {
            callback: (index: any) => {
              return this.lineData.labels[index];
            }
          },
          grid: {
            display: false
          }
        },
        y: {
          beginAtZero: true,
          ticks: {
            callback: (value: number) =>
              this.currencyPipe.transform(value, 'USD')
          }
        }
      },
      plugins: {
        legend: {
          display: true,
          position: 'bottom'
        },
        tooltip: {
          callbacks: {
            label: (context: any) =>
              this.currencyPipe.transform(context.raw, 'USD')
          }
        },
        datalabels: {
          display: true,
          align: 'top',
          formatter: (value: number) =>
            this.currencyPipe.transform(value, 'USD')
        }
      }
    };
  }

  getUniqueDates(data: MyFxBookDailyGainsGroupedByAccountDto[]): string[] {
    const dates = data.flatMap((group) =>
      (group.dailyGains ?? []).map((gain) => {
        const rawDate = gain.date || null;

        if (rawDate) {
          const [day, month, year] = rawDate.split(' ')[0].split('/');
          const validDate = new Date(+year, +month - 1, +day);
          return this.datePipe.transform(validDate, 'dd/MM/yyyy');
        }

        return null;
      })
    );

    return Array.from(
      new Set(dates.filter((date) => date !== null))
    ) as string[];
  }
}
