/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addUserLoginInformationAsync } from '../fn/user/add-user-login-information-async';
import { AddUserLoginInformationAsync$Params } from '../fn/user/add-user-login-information-async';
import { addUserLoginInformationAsync$Plain } from '../fn/user/add-user-login-information-async-plain';
import { AddUserLoginInformationAsync$Plain$Params } from '../fn/user/add-user-login-information-async-plain';
import { ContractDetailsDtoApiResponse } from '../models/contract-details-dto-api-response';
import { createB2CUserAsync } from '../fn/user/create-b-2-c-user-async';
import { CreateB2CUserAsync$Params } from '../fn/user/create-b-2-c-user-async';
import { createB2CUserAsync$Plain } from '../fn/user/create-b-2-c-user-async-plain';
import { CreateB2CUserAsync$Plain$Params } from '../fn/user/create-b-2-c-user-async-plain';
import { CreatedApiResponse } from '../models/created-api-response';
import { createUserAsync } from '../fn/user/create-user-async';
import { CreateUserAsync$Params } from '../fn/user/create-user-async';
import { createUserAsync$Plain } from '../fn/user/create-user-async-plain';
import { CreateUserAsync$Plain$Params } from '../fn/user/create-user-async-plain';
import { createUserContractAsync } from '../fn/user/create-user-contract-async';
import { CreateUserContractAsync$Params } from '../fn/user/create-user-contract-async';
import { createUserContractAsync$Plain } from '../fn/user/create-user-contract-async-plain';
import { CreateUserContractAsync$Plain$Params } from '../fn/user/create-user-contract-async-plain';
import { editUserAsync } from '../fn/user/edit-user-async';
import { EditUserAsync$Params } from '../fn/user/edit-user-async';
import { editUserAsync$Plain } from '../fn/user/edit-user-async-plain';
import { EditUserAsync$Plain$Params } from '../fn/user/edit-user-async-plain';
import { getCurrentUserAsync } from '../fn/user/get-current-user-async';
import { GetCurrentUserAsync$Params } from '../fn/user/get-current-user-async';
import { getCurrentUserAsync$Plain } from '../fn/user/get-current-user-async-plain';
import { GetCurrentUserAsync$Plain$Params } from '../fn/user/get-current-user-async-plain';
import { getUserAsync } from '../fn/user/get-user-async';
import { GetUserAsync$Params } from '../fn/user/get-user-async';
import { getUserAsync$Plain } from '../fn/user/get-user-async-plain';
import { GetUserAsync$Plain$Params } from '../fn/user/get-user-async-plain';
import { getUserContractAsync } from '../fn/user/get-user-contract-async';
import { GetUserContractAsync$Params } from '../fn/user/get-user-contract-async';
import { getUserContractAsync$Plain } from '../fn/user/get-user-contract-async-plain';
import { GetUserContractAsync$Plain$Params } from '../fn/user/get-user-contract-async-plain';
import { getUserRolesAsync } from '../fn/user/get-user-roles-async';
import { GetUserRolesAsync$Params } from '../fn/user/get-user-roles-async';
import { getUserRolesAsync$Plain } from '../fn/user/get-user-roles-async-plain';
import { GetUserRolesAsync$Plain$Params } from '../fn/user/get-user-roles-async-plain';
import { Int32ApiResponse } from '../models/int-32-api-response';
import { RoleDtoListApiResponse } from '../models/role-dto-list-api-response';
import { searchUsersAsync } from '../fn/user/search-users-async';
import { SearchUsersAsync$Params } from '../fn/user/search-users-async';
import { searchUsersAsync$Plain } from '../fn/user/search-users-async-plain';
import { SearchUsersAsync$Plain$Params } from '../fn/user/search-users-async-plain';
import { SuccessApiResponse } from '../models/success-api-response';
import { UserDetailsDtoApiResponse } from '../models/user-details-dto-api-response';
import { UserDtoApiResponse } from '../models/user-dto-api-response';
import { UserDtoSearchResponse } from '../models/user-dto-search-response';

@Injectable({ providedIn: 'root' })
export class UserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `searchUsersAsync()` */
  static readonly SearchUsersAsyncPath = '/api/user';

  /**
   * Search users by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchUsersAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchUsersAsync$Plain$Response(params?: SearchUsersAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDtoSearchResponse>> {
    return searchUsersAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search users by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchUsersAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchUsersAsync$Plain(params?: SearchUsersAsync$Plain$Params, context?: HttpContext): Observable<UserDtoSearchResponse> {
    return this.searchUsersAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDtoSearchResponse>): UserDtoSearchResponse => r.body)
    );
  }

  /**
   * Search users by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchUsersAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchUsersAsync$Response(params?: SearchUsersAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDtoSearchResponse>> {
    return searchUsersAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Search users by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchUsersAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchUsersAsync(params?: SearchUsersAsync$Params, context?: HttpContext): Observable<UserDtoSearchResponse> {
    return this.searchUsersAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDtoSearchResponse>): UserDtoSearchResponse => r.body)
    );
  }

  /** Path part for operation `createUserAsync()` */
  static readonly CreateUserAsyncPath = '/api/user';

  /**
   * Create user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUserAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUserAsync$Plain$Response(params?: CreateUserAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreatedApiResponse>> {
    return createUserAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Create user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUserAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUserAsync$Plain(params?: CreateUserAsync$Plain$Params, context?: HttpContext): Observable<CreatedApiResponse> {
    return this.createUserAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreatedApiResponse>): CreatedApiResponse => r.body)
    );
  }

  /**
   * Create user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUserAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUserAsync$Response(params?: CreateUserAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<CreatedApiResponse>> {
    return createUserAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Create user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUserAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUserAsync(params?: CreateUserAsync$Params, context?: HttpContext): Observable<CreatedApiResponse> {
    return this.createUserAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreatedApiResponse>): CreatedApiResponse => r.body)
    );
  }

  /** Path part for operation `getUserAsync()` */
  static readonly GetUserAsyncPath = '/api/user/{id}';

  /**
   * Get user by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAsync$Plain$Response(params: GetUserAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDetailsDtoApiResponse>> {
    return getUserAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get user by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAsync$Plain(params: GetUserAsync$Plain$Params, context?: HttpContext): Observable<UserDetailsDtoApiResponse> {
    return this.getUserAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDetailsDtoApiResponse>): UserDetailsDtoApiResponse => r.body)
    );
  }

  /**
   * Get user by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAsync$Response(params: GetUserAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDetailsDtoApiResponse>> {
    return getUserAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Get user by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAsync(params: GetUserAsync$Params, context?: HttpContext): Observable<UserDetailsDtoApiResponse> {
    return this.getUserAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDetailsDtoApiResponse>): UserDetailsDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `editUserAsync()` */
  static readonly EditUserAsyncPath = '/api/user/{id}';

  /**
   * Edit user information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editUserAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editUserAsync$Plain$Response(params: EditUserAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDetailsDtoApiResponse>> {
    return editUserAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit user information.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editUserAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editUserAsync$Plain(params: EditUserAsync$Plain$Params, context?: HttpContext): Observable<UserDetailsDtoApiResponse> {
    return this.editUserAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDetailsDtoApiResponse>): UserDetailsDtoApiResponse => r.body)
    );
  }

  /**
   * Edit user information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editUserAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editUserAsync$Response(params: EditUserAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDetailsDtoApiResponse>> {
    return editUserAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit user information.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editUserAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editUserAsync(params: EditUserAsync$Params, context?: HttpContext): Observable<UserDetailsDtoApiResponse> {
    return this.editUserAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDetailsDtoApiResponse>): UserDetailsDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `getCurrentUserAsync()` */
  static readonly GetCurrentUserAsyncPath = '/api/user/current';

  /**
   * Get current user information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentUserAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserAsync$Plain$Response(params?: GetCurrentUserAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDtoApiResponse>> {
    return getCurrentUserAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get current user information.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentUserAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserAsync$Plain(params?: GetCurrentUserAsync$Plain$Params, context?: HttpContext): Observable<UserDtoApiResponse> {
    return this.getCurrentUserAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDtoApiResponse>): UserDtoApiResponse => r.body)
    );
  }

  /**
   * Get current user information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentUserAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserAsync$Response(params?: GetCurrentUserAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDtoApiResponse>> {
    return getCurrentUserAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Get current user information.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCurrentUserAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentUserAsync(params?: GetCurrentUserAsync$Params, context?: HttpContext): Observable<UserDtoApiResponse> {
    return this.getCurrentUserAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDtoApiResponse>): UserDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `createB2CUserAsync()` */
  static readonly CreateB2CUserAsyncPath = '/api/user/b2c';

  /**
   * Create User (used by B2C).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createB2CUserAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createB2CUserAsync$Plain$Response(params?: CreateB2CUserAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreatedApiResponse>> {
    return createB2CUserAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Create User (used by B2C).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createB2CUserAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createB2CUserAsync$Plain(params?: CreateB2CUserAsync$Plain$Params, context?: HttpContext): Observable<CreatedApiResponse> {
    return this.createB2CUserAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreatedApiResponse>): CreatedApiResponse => r.body)
    );
  }

  /**
   * Create User (used by B2C).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createB2CUserAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createB2CUserAsync$Response(params?: CreateB2CUserAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<CreatedApiResponse>> {
    return createB2CUserAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Create User (used by B2C).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createB2CUserAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createB2CUserAsync(params?: CreateB2CUserAsync$Params, context?: HttpContext): Observable<CreatedApiResponse> {
    return this.createB2CUserAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreatedApiResponse>): CreatedApiResponse => r.body)
    );
  }

  /** Path part for operation `getUserRolesAsync()` */
  static readonly GetUserRolesAsyncPath = '/api/user/roles';

  /**
   * Get user's roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserRolesAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserRolesAsync$Plain$Response(params?: GetUserRolesAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleDtoListApiResponse>> {
    return getUserRolesAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get user's roles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserRolesAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserRolesAsync$Plain(params?: GetUserRolesAsync$Plain$Params, context?: HttpContext): Observable<RoleDtoListApiResponse> {
    return this.getUserRolesAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleDtoListApiResponse>): RoleDtoListApiResponse => r.body)
    );
  }

  /**
   * Get user's roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserRolesAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserRolesAsync$Response(params?: GetUserRolesAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<RoleDtoListApiResponse>> {
    return getUserRolesAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Get user's roles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserRolesAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserRolesAsync(params?: GetUserRolesAsync$Params, context?: HttpContext): Observable<RoleDtoListApiResponse> {
    return this.getUserRolesAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<RoleDtoListApiResponse>): RoleDtoListApiResponse => r.body)
    );
  }

  /** Path part for operation `createUserContractAsync()` */
  static readonly CreateUserContractAsyncPath = '/api/user/{id}/contract';

  /**
   * Create user's contract.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUserContractAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUserContractAsync$Plain$Response(params: CreateUserContractAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Int32ApiResponse>> {
    return createUserContractAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Create user's contract.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUserContractAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUserContractAsync$Plain(params: CreateUserContractAsync$Plain$Params, context?: HttpContext): Observable<Int32ApiResponse> {
    return this.createUserContractAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Int32ApiResponse>): Int32ApiResponse => r.body)
    );
  }

  /**
   * Create user's contract.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUserContractAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUserContractAsync$Response(params: CreateUserContractAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<Int32ApiResponse>> {
    return createUserContractAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Create user's contract.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createUserContractAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createUserContractAsync(params: CreateUserContractAsync$Params, context?: HttpContext): Observable<Int32ApiResponse> {
    return this.createUserContractAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<Int32ApiResponse>): Int32ApiResponse => r.body)
    );
  }

  /** Path part for operation `getUserContractAsync()` */
  static readonly GetUserContractAsyncPath = '/api/user/{id}/contract/{contractId}';

  /**
   * Get user's contract by contract id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserContractAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserContractAsync$Plain$Response(params: GetUserContractAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDetailsDtoApiResponse>> {
    return getUserContractAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get user's contract by contract id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserContractAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserContractAsync$Plain(params: GetUserContractAsync$Plain$Params, context?: HttpContext): Observable<ContractDetailsDtoApiResponse> {
    return this.getUserContractAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDetailsDtoApiResponse>): ContractDetailsDtoApiResponse => r.body)
    );
  }

  /**
   * Get user's contract by contract id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserContractAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserContractAsync$Response(params: GetUserContractAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDetailsDtoApiResponse>> {
    return getUserContractAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Get user's contract by contract id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserContractAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserContractAsync(params: GetUserContractAsync$Params, context?: HttpContext): Observable<ContractDetailsDtoApiResponse> {
    return this.getUserContractAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDetailsDtoApiResponse>): ContractDetailsDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `addUserLoginInformationAsync()` */
  static readonly AddUserLoginInformationAsyncPath = '/api/user/login-information';

  /**
   * Save user login information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addUserLoginInformationAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addUserLoginInformationAsync$Plain$Response(params?: AddUserLoginInformationAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return addUserLoginInformationAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Save user login information.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addUserLoginInformationAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addUserLoginInformationAsync$Plain(params?: AddUserLoginInformationAsync$Plain$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.addUserLoginInformationAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

  /**
   * Save user login information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addUserLoginInformationAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addUserLoginInformationAsync$Response(params?: AddUserLoginInformationAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return addUserLoginInformationAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Save user login information.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addUserLoginInformationAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addUserLoginInformationAsync(params?: AddUserLoginInformationAsync$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.addUserLoginInformationAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

}
