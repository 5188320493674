/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { AlertsService } from '@app/core/services';
import { TranslateModule } from '@ngx-translate/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {
  Country,
  EditFinancialAdvisorCommand,
  RoleDto,
  UserDetailsDto
} from '@app/data/amn-api/models';
import { first } from 'rxjs';
import { ROLE_USER, roles } from '@app/data/models';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { SharedModule } from '@app/shared/shared.module';
import { UserService } from '@app/data/amn-api/services';
import { DropdownChangeEvent } from 'primeng/dropdown';
import {
  getDescriptionById,
  getSeverityById
} from '@app/core/helpers/roles.helper';
import { SelectItem } from 'primeng/api';
import { ErrorService } from '@app/core/services/error.service';
import { FinancialAdvisorFormComponent } from '@app/modules/financial-advisors/components/financial-advisor-form/financial-advisor-form.component';

export enum PersonalIdentificationTypeEnum {
  INE = 'INE',
  SSN = 'SSN',
  PASSPORT = 'Passport'
}

@Component({
  standalone: true,
  selector: 'app-edit-customer',
  imports: [
    PrimeNgModule,
    SharedModule,
    TranslateModule,
    FinancialAdvisorFormComponent
  ],
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.scss']
})
export class EditCustomerComponent implements OnInit, OnChanges {
  @Input() customerId: number = 0;
  @Input() customer!: UserDetailsDto;
  @Input() isFinancialAdvisorForm = false;
  @Input() commissionsFaDetails!: EditFinancialAdvisorCommand;

  @Output() cancelCustomer = new EventEmitter<void>();
  @Output() customerSubmission = new EventEmitter<void>();
  @Output() nameChanged = new EventEmitter<string>();

  isEditing: boolean = false;

  ObjectId?: string = '';
  roles: RoleDto[] = [];
  submitLabel: string = 'Common.Add';
  private initialUserInfo: any = null;
  countryOptions!: SelectItem[];
  showNeighborhood = false;
  personalIdentificationTypeOpts!: SelectItem[];
  officesOpts: SelectItem[] = [];
  isSendingForm = false;

  userForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private customerService: UserService,
    private alertsService: AlertsService,
    private errorService: ErrorService
  ) {}

  getSeverityByRoleId = getSeverityById;
  getSeverityDescriptionById = getDescriptionById;

  ngOnInit() {
    this.roles = roles;
    this.initForm();
    this.subscribeToNameChanges();

    this.countryOptions = [
      { label: 'Mexico', value: Country.Mx },
      { label: 'United States', value: Country.Us }
    ];

    this.personalIdentificationTypeOpts = [
      { label: 'INE', value: PersonalIdentificationTypeEnum.INE },
      { label: 'SSN', value: PersonalIdentificationTypeEnum.SSN },
      {
        label: 'Passport Number',
        value: PersonalIdentificationTypeEnum.PASSPORT
      }
    ];

    if (this.customer) {
      this.fillFormWithCustomerData();
    }

    this.setEditForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['customer'] && !changes['customer'].isFirstChange()) {
      this.fillFormWithCustomerData();
    }
  }

  initForm() {
    this.userForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.maxLength(100)]],
      email: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(50)]
      ],
      phoneNumber: ['', Validators.maxLength(20)],
      taxId: ['', [Validators.maxLength(13), this.rfcValidator]],
      roles: [ROLE_USER],
      addressId: new FormControl<number | null>(null),
      personalIdentificationType:
        new FormControl<PersonalIdentificationTypeEnum | null>(null),
      personalIdentificationNumber: new FormControl<string | null>(null, [
        Validators.maxLength(25)
      ]),
      taxSituationCertificateDate: null,
      street: new FormControl<string>('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      exteriorNumber: new FormControl<string>('', [Validators.required]),
      interiorNumber: new FormControl<string>(''),
      zipCode: new FormControl<string>('', [
        Validators.required,
        Validators.maxLength(5)
      ]),
      city: new FormControl<string>('', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      state: new FormControl<string>('', [
        Validators.required,
        Validators.maxLength(50)
      ]),
      country: new FormControl<Country>(Country.Mx, [Validators.required]),
      neighborhood: new FormControl<string>('')
    });
  }

  rfcValidator(control: AbstractControl): ValidationErrors | null {
    const rfcPattern = /^([A-ZÑ&]{3,4})\d{6}([A-Z\d]{3})?$/;
    const value = control.value;
    if (!value) {
      return null;
    }
    const isValid = rfcPattern.test(value);
    return isValid ? null : { invalidRFC: true };
  }

  subscribeToNameChanges() {
    this.userForm.get('name')?.valueChanges.subscribe(() => {
      this.updateNameChanged();
    });
    this.userForm.get('lastName')?.valueChanges.subscribe(() => {
      this.updateNameChanged();
    });
  }

  updateNameChanged() {
    const name = this.userForm.get('name')?.value || '';
    const lastName = this.userForm.get('lastName')?.value || '';
    this.nameChanged.emit(`${name} ${lastName}`);
  }

  closeDialog(): void {
    this.cancelCustomer.emit();
  }

  private fillFormWithCustomerData() {
    this.submitLabel = 'Common.Save';

    const addressId =
      !!this.customer.userAddresses && this.customer.userAddresses[0]?.id
        ? this.customer.userAddresses[0]?.id
        : null;
    const street =
      !!this.customer.userAddresses && this.customer.userAddresses[0]?.street
        ? this.customer.userAddresses[0]?.street
        : '';
    const exteriorNumber =
      !!this.customer.userAddresses &&
      this.customer.userAddresses[0]?.exteriorNumber
        ? this.customer.userAddresses[0]?.exteriorNumber
        : '';
    const interiorNumber =
      !!this.customer.userAddresses &&
      this.customer.userAddresses[0]?.interiorNumber
        ? this.customer.userAddresses[0]?.interiorNumber
        : '';
    const zipCode =
      !!this.customer.userAddresses && this.customer.userAddresses[0]?.zipCode
        ? this.customer.userAddresses[0]?.zipCode
        : '';
    const city =
      !!this.customer.userAddresses && this.customer.userAddresses[0]?.city
        ? this.customer.userAddresses[0]?.city
        : '';
    const state =
      !!this.customer.userAddresses && this.customer.userAddresses[0]?.state
        ? this.customer.userAddresses[0]?.state
        : '';
    const country =
      !!this.customer.userAddresses && this.customer.userAddresses[0]?.country
        ? this.customer.userAddresses[0]?.country
        : 'MX';
    const neighborhood =
      !!this.customer.userAddresses &&
      this.customer.userAddresses[0]?.neighborhood
        ? this.customer.userAddresses[0]?.neighborhood
        : '';

    this.showNeighborhood = country === 'MX' ? true : false;

    const customerRoles = Array.isArray(this.customer.roles)
      ? this.customer.roles.map((r) => r.id)
      : [];

    this.initialUserInfo = {
      name: this.customer.name,
      lastName: this.customer.lastName,
      email: this.customer.email,
      phoneNumber: this.customer.phoneNumber,
      taxId: this.customer.taxId,
      roles: customerRoles,
      personalIdentificationType: this.customer.personalIdentificationType,
      personalIdentificationNumber: this.customer.personalIdentificationNumber,
      taxSituationCertificateDate: this.customer.taxSituationCertificateDate
        ? new Date(this.customer.taxSituationCertificateDate)
        : null,
      addressId,
      street,
      exteriorNumber,
      interiorNumber,
      zipCode,
      city,
      state,
      country,
      neighborhood
    };

    this.userForm.patchValue(this.initialUserInfo);

    if (this.ObjectId) {
      this.userForm.controls['email'].disable();
    }
  }

  onCancelClick() {
    this.isEditing = false;
    this.setEditForm();
    if (this.initialUserInfo) this.userForm.reset(this.initialUserInfo);
    else this.userForm.reset();
    this.cancelCustomer.emit();
  }

  public resetForm(userInfo: any) {
    this.initialUserInfo = userInfo;
    this.userForm.reset(this.initialUserInfo);
  }

  hasError(formControl: string, validationName: string): boolean | undefined {
    return (
      this.userForm.get(formControl)?.hasError(validationName) &&
      this.userForm.get(formControl)?.touched
    );
  }

  updateOrInsert() {
    if (!this.userForm?.valid) {
      return;
    }
    const userInfo = this.userForm.value;
    userInfo.id = this.customerId;
    // Set user address
    userInfo.userAddresses = [
      {
        id: userInfo.addressId ?? 0,
        street: userInfo.street,
        exteriorNumber: userInfo.exteriorNumber,
        interiorNumber: userInfo.interiorNumber,
        zipCode: userInfo.zipCode,
        city: userInfo.city,
        state: userInfo.state,
        country: userInfo.country,
        neighborhood: userInfo.neighborhood
      }
    ];

    this.isSendingForm = true;
    this.customerService
      .editUserAsync({ id: userInfo.id, body: userInfo })
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertsService.addSuccess(
            'Alerts.UserEdited',
            'Alerts.UserEditedCorrectly',
            true
          );
          this.resetForm(userInfo);
          this.isEditing = false;
          this.setEditForm();
          this.customerSubmission.emit();
        },
        error: (error: unknown) => {
          this.errorService.handleError(error, {
            badRequest: 'Alerts.ErrorEditingUser'
          });
          this.isSendingForm = false;
        },
        complete: () => (this.isSendingForm = false)
      });
  }

  handleCountrySelected(country: DropdownChangeEvent) {
    if (country.value === Country.Mx) {
      this.showNeighborhood = true;
    } else {
      this.showNeighborhood = false;
    }
  }

  onEditClick() {
    this.isEditing = !this.isEditing;
    this.setEditForm();
  }

  private setEditForm() {
    if (this.isEditing) {
      this.userForm.enable();
    } else {
      this.userForm.disable();
    }
  }
}
