import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { ContractDepositTransactionFormComponent } from '../contract-deposit-transaction-form/contract-deposit-transaction-form.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-add-transaction-document',
  standalone: true,
  imports: [
    PrimeNgModule,
    ContractDepositTransactionFormComponent,
    TranslateModule
  ],
  templateUrl: './add-transaction-document.component.html',
  styleUrl: './add-transaction-document.component.scss'
})
export class AddTransactionDocumentComponent implements OnDestroy {
  visible: boolean = false;
  @Input() contractId: number = 0;
  @Input() transactionId: number = 0;

  @Output() cancelDeposit = new EventEmitter<void>();
  @Output() uploadDocument = new EventEmitter<void>();

  @ViewChild(ContractDepositTransactionFormComponent)
  addDepositTransaction!: ContractDepositTransactionFormComponent;

  ngOnDestroy(): void {
    this.addDepositTransaction.cleanForm();
  }

  onAddClick(): void {
    this.visible = true;
    this.addDepositTransaction.cleanForm();
  }

  onCancelClick(): void {
    this.visible = false;
    this.cancelDeposit.emit();
  }

  onSubmit(): void {
    this.visible = false;
    this.uploadDocument.emit();
  }
}
