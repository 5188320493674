<section class="m-3">
  <form
    (ngSubmit)="updateOrInsert()"
    #customerForm="ngForm"
    [formGroup]="financialAdvisorForm">
    <div class="grid formgrid p-fluid">
      <div class="field col-12">
        <span class="font-medium text-xl text-900 mt-3 mb-3 block">{{
          'FinancialAdvisors.Commission' | translate
        }}</span>
      </div>

      <div class="field col-12 md:col-12 lg:col-4 sm:col-12">
        <app-amn-input
          id="commission"
          label="{{ 'FinancialAdvisors.Commission' | translate }}"
          formControlName="commission"
          [errorMessages]="{
            required: 'Commission is required'
          }">
        </app-amn-input>
      </div>
      <div class="field col-12 md:col-12 lg:col-4 sm:col-12">
        <label for="officeId" class="font-medium text-900">
          {{ 'FinancialAdvisors.Office' | translate }}
        </label>
        <p-dropdown
          id="officeId"
          formControlName="officeId"
          [options]="officesOpts"
          optionValue="value"
          optionLabel="label"
          placeholder="{{ 'Common.Select' | translate }}"
          appendTo="body"
          (onChange)="handleSetOffice($event)">
        </p-dropdown>
      </div>
      <div class="field col-12 md:col-12 lg:col-4 sm:col-12">
        <app-amn-input
          id="marketing"
          label="{{ 'FinancialAdvisors.Marketing' | translate }}"
          formControlName="marketing"
          [errorMessages]="{
            maxlength: 'Marketing is required.'
          }">
        </app-amn-input>
      </div>

      @if (isMainForm) {
        <div class="w-full flex justify-content-end flex-wrap">
          <p-button
            label="{{ 'Common.Cancel' | translate }}"
            icon="pi pi-times"
            class="w-auto mr-3 mt-3"
            [outlined]="true"
            severity="secondary"
            (click)="onCancelClick()"
            [style.display]="this.isEditing ? 'block' : 'none'"></p-button>
          <p-button
            type="submit"
            label="{{ submitLabel | translate }}"
            icon="pi pi-check"
            class="w-auto mt-3 mr-3"
            [outlined]="true"
            severity="primary"
            [disabled]="!this.financialAdvisorForm.valid"
            [style.display]="isEditing ? 'block' : 'none'"></p-button>
          <p-button
            label="{{ 'Common.Edit' | translate }}"
            icon="pi pi-user-edit"
            class="w-auto mt-3 mr-3"
            (click)="onEditClick()"
            [style.display]="this.isEditing ? 'none' : 'block'"></p-button>
        </div>
      }
    </div>
  </form>
</section>
