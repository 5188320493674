import {
  Component,
  EventEmitter,
  Output,
  ViewChild,
  OnInit,
  inject,
  OnDestroy
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { EditCustomerComponent } from '../edit-customer/edit-customer.component';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared/shared.module';
import { UserService } from '@app/data/amn-api/services';
import { Subscription } from 'rxjs';
import { ROLE_USER } from '@app/data/models';
import { AlertsService } from '@app/core/services';
import { ErrorService } from '@app/core/services/error.service';

@Component({
  standalone: true,
  selector: 'app-add-customer',
  imports: [
    PrimeNgModule,
    EditCustomerComponent,
    TranslateModule,
    SharedModule
  ],
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit, OnDestroy {
  private fb = inject(FormBuilder);
  private customerService = inject(UserService);
  private alertsService = inject(AlertsService);
  private subscription = new Subscription();
  private errorService = inject(ErrorService);

  @Output() addUser = new EventEmitter<void>();
  @ViewChild(EditCustomerComponent)
  editCustomerComponent!: EditCustomerComponent;

  visible: boolean = false;
  userForm!: FormGroup;

  ngOnInit(): void {
    this.userForm = this.fb.group({
      name: new FormControl<string>('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      lastName: new FormControl<string>('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      email: new FormControl<string>('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(50)
      ])
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onAddClick(): void {
    this.visible = true;
    this.userForm.reset();
  }

  onCancelClick(): void {
    this.visible = false;
  }

  onSubmit(): void {
    if (this.userForm.valid) {
      const newUserInfo = { ...this.userForm.value, roles: [ROLE_USER] };

      this.subscription.add(
        this.customerService.createUserAsync({ body: newUserInfo }).subscribe({
          next: () => {
            this.alertsService.addSuccess(
              'Alerts.UserAdded',
              'Alerts.UserAddedCorrectly',
              true
            );
            this.visible = false;
            this.addUser.emit();
          },
          error: (error: unknown) => {
            this.errorService.handleError(error, {
              badRequest: 'Alerts.ErrorCreatingUser'
            });
          }
        })
      );
    }
  }
}
