import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TotalInvestmentsDto } from '@app/data/amn-api/models';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';

@Component({
  selector: 'app-total-investments',
  standalone: true,
  imports: [PrimeNgModule, CommonModule, TranslateModule],
  templateUrl: './total-investments.component.html',
  styleUrl: './total-investments.component.scss'
})
export class TotalInvestmentsComponent {
  @Input() customersTotals: TotalInvestmentsDto | undefined;
}
