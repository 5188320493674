@if (settingId) {
  <p-button
    icon="pi pi-pencil"
    (click)="onAddClick()"
    [rounded]="true"
    [text]="true"
    pTooltip="{{ 'Common.Edit' | translate }}"
    tooltipPosition="left"></p-button>
} @else {
  <p-button
    (click)="onAddClick()"
    label="{{ 'Common.AddNew' | translate }}"
    icon="pi pi-plus"
    [outlined]="true"></p-button>
}
<p-dialog
  [header]="settingId ? titleToEdit : titleToAdd"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false">
  <app-settings-form
    (submitSetting)="onSubmit()"
    (cancelSetting)="onCancelClick()"
    [settingId]="settingId">
  </app-settings-form>
</p-dialog>
