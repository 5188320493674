<p-button
  (click)="onAddClick()"
  label="{{ 'Common.AddNew' | translate }}"
  icon="pi pi-user-plus"
  [outlined]="true"></p-button>
<p-dialog
  header="{{ 'FinancialAdvisors.Title' | translate }}"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false">
  <form (ngSubmit)="onSubmit()" [formGroup]="financialAdvisorForm">
    <div class="grid formgrid p-fluid">
      <div class="field col-12 md:col-12">
        <label for="email" class="font-medium text-900">{{
          'FinancialAdvisors.Email' | translate
        }}</label>
        <p-autoComplete
          formControlName="email"
          [suggestions]="filteredUsers"
          field="email"
          [forceSelection]="false"
          (completeMethod)="filterUsers($event)"
          (onSelect)="onUserSelect($event)"
          (onClear)="clearFormValues()"
          [dropdown]="true"
          [showEmptyMessage]="true"
          [showClear]="true"
          emptyMessage="No se encontraron coincidencias">
          <ng-template let-user pTemplate="item">
            <div class="customer-item">
              <strong>{{ user.email }}</strong
              ><br />
              {{ user.name }} {{ user.lastName }}<br />
              {{ user.phoneNumber }}
            </div>
          </ng-template>
        </p-autoComplete>
      </div>
      <div class="field col-6">
        <app-amn-input
          id="name"
          label="{{ 'Common.Name' | translate }}"
          formControlName="name"
          [errorMessages]="{
            required: 'Name is required',
            maxlength: 'Name cannot be more than 100 characters'
          }">
        </app-amn-input>
      </div>
      <div class="field col-6">
        <app-amn-input
          id="lastName"
          label="{{ 'FinancialAdvisors.LastName' | translate }}"
          formControlName="lastName"
          [errorMessages]="{
            required: 'Last Name is required.',
            maxlength: 'Last name cannot be more than 100 characters'
          }">
        </app-amn-input>
      </div>
      <div class="field col-6">
        <app-amn-input-phone
          id="phoneNumber"
          label="{{ 'FinancialAdvisors.Phone' | translate }}"
          formControlName="phoneNumber"
          [errorMessages]="{ required: 'Phone is required.' }">
        </app-amn-input-phone>
      </div>
      <div class="field col-6">
        <app-amn-input
          id="commission"
          label="{{ 'FinancialAdvisors.Commission' | translate }}"
          formControlName="commission"
          [errorMessages]="{
            required: 'Commission is required',
            maxlength: 'Commission cannot be more than 100 characters'
          }">
        </app-amn-input>
      </div>
      <div class="field col-6">
        <app-amn-input
          id="marketing"
          label="{{ 'FinancialAdvisors.Marketing' | translate }}"
          formControlName="marketing"
          [errorMessages]="{
            required: 'marketing is required',
            maxlength: 'marketing cannot be more than 100 characters'
          }">
        </app-amn-input>
      </div>
      <div class="field col-6">
        <label for="officeId" class="font-medium text-900">
          {{ 'FinancialAdvisors.Office' | translate }}
        </label>
        <p-dropdown
          id="officeId"
          formControlName="officeId"
          [options]="officesOpts"
          optionValue="value"
          optionLabel="label"
          placeholder="{{ 'Common.Select' | translate }}"
          appendTo="body"
          (onChange)="handleSetOffice($event)">
        </p-dropdown>
      </div>
      <div class="field col-6">
        <app-amn-single-file-upload
          #amnFileUploader
          (uploadingFile)="onUpload($event)"></app-amn-single-file-upload>
      </div>
      <div class="w-full flex justify-content-end flex-wrap">
        <p-button
          label="{{ 'Common.Cancel' | translate }}"
          icon="pi pi-times"
          class="w-auto mr-3 mt-3"
          [outlined]="true"
          severity="secondary"
          (click)="onCancelClick()"></p-button>
        <p-button
          type="submit"
          label="{{ 'Common.Save' | translate }}"
          icon="pi pi-check"
          class="w-auto mt-3 mr-3"
          [outlined]="true"
          severity="primary"
          [disabled]="this.financialAdvisorForm.invalid"></p-button>
      </div>
    </div>
  </form>
</p-dialog>
