<div class="grid">
  <div class="col-12">
    <div class="card">
      <div class="flex flex-wrap align-items-center justify-content-between">
        <h3>{{ 'Menu.FinancialAdvisors' | translate }}</h3>
      </div>
      <p-table
        #dt
        [value]="financialAdvisors"
        [lazy]="true"
        (onLazyLoad)="onLazyLoad($event)"
        [totalRecords]="totalRecords"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        currentPageReportTemplate="{{ 'Common.PaginatorTemplate' | translate }}"
        [rowsPerPageOptions]="[10, 25, 50]"
        [globalFilterFields]="['name', 'email']"
        [scrollable]="true"
        styleClass="mt-3">
        <ng-template pTemplate="caption">
          <div
            class="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div class="flex flex-wrap">
              <div class="p-input-icon-left w-full sm:w-25rem sm:flex-order-0">
                <i class="pi pi-search" id="icon-search"></i>
                <input
                  pInputText
                  type="text"
                  [(ngModel)]="searchCriteria"
                  (input)="onGlobalFilter(dt, $event)"
                  placeholder="{{ 'Customers.Search' | translate }}"
                  class="w-full" />
              </div>
            </div>
            <app-add-financial-advisor
              (addFinancialAdvisor)="onAddFinancialAdvisor()" />
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="Name">
              {{ 'Common.Name' | translate
              }}<p-sortIcon field="Name"></p-sortIcon>
            </th>
            <th class="text-center">{{ 'Common.Photo' | translate }}</th>
            <th pSortableColumn="Email">
              {{ 'Customers.Email' | translate
              }}<p-sortIcon field="Email"></p-sortIcon>
            </th>
            <th pSortableColumn="Phone">
              {{ 'Common.Phone' | translate
              }}<p-sortIcon field="Phone"></p-sortIcon>
            </th>
            <th pSortableColumn="Commission">
              {{ 'FinancialAdvisors.Commission' | translate
              }}<p-sortIcon field="Commission"></p-sortIcon>
            </th>
            <th pSortableColumn="Office">
              {{ 'FinancialAdvisors.Office' | translate
              }}<p-sortIcon field="Office"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-financialAdvisors>
          <tr>
            <td class="font-bold">{{ financialAdvisors.fullName }}</td>
            <td class="text-center">
              <app-avatar
                [displayName]="financialAdvisors.fullName"
                size="large"
                shape="circle"
                [image]="financialAdvisors.photoUrl"></app-avatar>
            </td>
            <td>{{ financialAdvisors.email }}</td>
            <td>{{ financialAdvisors.phoneNumber }}</td>
            <td>{{ financialAdvisors.commission }}</td>
            <td>{{ financialAdvisors.office }}</td>
            <td class="text-center">
              <p-button
                icon="pi pi-user-edit"
                [rounded]="true"
                [text]="true"
                size="large"
                (onClick)="getDetailsPage(financialAdvisors)"
                pTooltip="{{ 'Common.AdvisorDetails' | translate }}"
                tooltipPosition="left" />
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">{{ 'Customers.NotFound' | translate }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<!-- <p-dialog
    id="dialog"
    header="{{'FinancialAdvisor.Edit'| translate}}"
    [(visible)]="visible"
    [style]="{ width: '50vw', height: '500px' }"
    (onHide)="visible = false"
    [modal]="true">
    <app-add-financial-advisor-form (onShowParentModal)="handleOnShowParentModal($event)" (onAddNewFinancialAdvisor)="onAddFinancialAdvisor()" [isToEdit]="true" [userDetails]="userToEdit" [random]="random"></app-add-financial-advisor-form>
</p-dialog> -->
