<div class="grid">
  @if (!isFaSection) {
    <app-total-investments
      class="w-full"
      [customersTotals]="customersTotals"></app-total-investments>
  }
  <div class="col-12 pl-0 pr-0">
    <div class="card">
      <h3>{{ 'Menu.CustomersInvestments' | translate }}</h3>
      <p-toast />
      <p-table
        #dt
        [value]="customersTotals?.customerInvestments ?? []"
        dataKey="userId"
        [tableStyle]="{ 'min-width': '60rem' }"
        [expandedRowKeys]="expandedRows"
        [lazy]="true"
        (onLazyLoad)="onLazyLoad($event)"
        [totalRecords]="totalRecords"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        currentPageReportTemplate="{{ 'Common.PaginatorTemplate' | translate }}"
        [rowsPerPageOptions]="[10, 25, 50]"
        [globalFilterFields]="[
          'contractNumber',
          'customerName',
          'interestType',
          'status'
        ]"
        [scrollable]="true">
        <ng-template pTemplate="caption">
          <div
            class="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div class="flex flex-wrap">
              <div
                class="p-input-icon-left w-full sm:w-25rem flex-order-1 sm:flex-order-0">
                <i class="pi pi-search" id="icon-search"></i>
                <input
                  pInputText
                  type="text"
                  [(ngModel)]="searchCriteriaCustomer"
                  (input)="onGlobalFilter(dt, $event)"
                  placeholder="{{ 'CustomersInvestments.Search' | translate }}"
                  class="w-full" />
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 2%"></th>
            <th pSortableColumn="customerName" style="width: 18%">
              {{ 'Common.Name' | translate
              }}<p-sortIcon field="customerName"></p-sortIcon>
            </th>
            <th pSortableColumn="activeContracts" style="width: 8%">
              {{ 'CustomersInvestments.ActiveContracts' | translate
              }}<p-sortIcon field="activeContracts"></p-sortIcon>
            </th>
            <th pSortableColumn="totalInvestedUSD" style="width: 12%">
              {{ 'CustomersInvestments.TotalInvested' | translate }}
              USD<p-sortIcon field="totalInvestedUSD"></p-sortIcon>
            </th>
            <th pSortableColumn="accruedInterestUSD" style="width: 12%">
              {{ 'CustomersInvestments.AccruedInterest' | translate }}
              USD<p-sortIcon field="accruedInterestUSD"></p-sortIcon>
            </th>
            <th pSortableColumn="totalDebtOwedUSD" style="width: 12%">
              {{ 'CustomersInvestments.TotalDebtOwed' | translate }}
              USD<p-sortIcon field="totalDebtOwedUSD"></p-sortIcon>
            </th>
            <th pSortableColumn="totalInvestedMXN" style="width: 12%">
              {{ 'CustomersInvestments.TotalInvested' | translate }}
              MXN<p-sortIcon field="totalInvestedMXN"></p-sortIcon>
            </th>
            <th pSortableColumn="accruedInterestMXN" style="width: 12%">
              {{ 'CustomersInvestments.AccruedInterest' | translate }}
              MXN<p-sortIcon field="accruedInterestMXN"></p-sortIcon>
            </th>
            <th pSortableColumn="totalDebtOwedMXN" style="width: 12%">
              {{ 'CustomersInvestments.TotalDebtOwed' | translate }}
              MXN<p-sortIcon field="totalDebtOwedMXN"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer let-expanded="expanded">
          <tr>
            <td>
              <p-button
                type="button"
                pRipple
                [pRowToggler]="customer"
                [text]="true"
                [rounded]="true"
                [plain]="true"
                [icon]="
                  expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                " />
            </td>
            <td>{{ customer.name }}</td>
            <td class="text-center">
              <i
                class="pi pi-file text-2xl"
                pBadge
                value="{{ customer.activeContracts }}"></i>
            </td>
            <td>{{ customer.totalInvestedUSD ?? 0 | currency }}</td>
            <td>{{ customer.accruedInterestUSD ?? 0 | currency }}</td>
            <td>{{ customer.totalDebtOwedUSD ?? 0 | currency }}</td>
            <td>{{ customer.totalInvestedMXN ?? 0 | currency }}</td>
            <td>{{ customer.accruedInterestMXN ?? 0 | currency }}</td>
            <td>{{ customer.totalDebtOwedMXN ?? 0 | currency }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-customer>
          <tr>
            <td colspan="9">
              <div class="p-3">
                <p-table [value]="customer.contractsDetails" dataKey="userId">
                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="contractNumber" style="width: 12%">
                        {{ 'CustomersInvestments.ContractNumber' | translate
                        }}<p-sortIcon field="contractNumber"></p-sortIcon>
                      </th>
                      <th
                        class="text-center"
                        pSortableColumn="currency"
                        style="width: 8%">
                        {{ 'CustomersInvestments.Currency' | translate
                        }}<p-sortIcon field="currency"></p-sortIcon>
                      </th>
                      <th pSortableColumn="deposits" style="width: 10%">
                        {{ 'CustomersInvestments.Deposits' | translate
                        }}<p-sortIcon field="deposits"></p-sortIcon>
                      </th>
                      <th pSortableColumn="profits" style="width: 10%">
                        {{ 'CustomersInvestments.Profits' | translate
                        }}<p-sortIcon field="profits"></p-sortIcon>
                      </th>
                      <th pSortableColumn="profitsBalance" style="width: 10%">
                        {{ 'CustomersInvestments.ProfitsBalance' | translate
                        }}<p-sortIcon field="profitsBalance"></p-sortIcon>
                      </th>
                      <th pSortableColumn="balance" style="width: 10%">
                        {{ 'CustomersInvestments.Balance' | translate
                        }}<p-sortIcon field="balance"></p-sortIcon>
                      </th>
                      <th pSortableColumn="interestType" style="width: 10%">
                        {{ 'CustomersInvestments.InterestType' | translate
                        }}<p-sortIcon field="interestType"></p-sortIcon>
                      </th>
                      <th pSortableColumn="interestRate" style="width: 10%">
                        {{ 'CustomersInvestments.InterestRate' | translate
                        }}<p-sortIcon field="interestRate"></p-sortIcon>
                      </th>
                      <th pSortableColumn="startDate" style="width: 10%">
                        {{ 'CustomersInvestments.StartDate' | translate
                        }}<p-sortIcon field="startDate"></p-sortIcon>
                      </th>
                      <th pSortableColumn="endDate" style="width: 10%">
                        {{ 'CustomersInvestments.EndDate' | translate
                        }}<p-sortIcon field="endDate"></p-sortIcon>
                      </th>
                      <th></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-contract>
                    <tr>
                      <td>{{ contract.contractNumber }}</td>
                      <td class="text-center">{{ contract.currency }}</td>
                      <td>{{ contract.deposits ?? 0 | currency }}</td>
                      <td>{{ contract.profits ?? 0 | currency }}</td>
                      <td>{{ contract.profitsBalance ?? 0 | currency }}</td>
                      <td>{{ contract.balance ?? 0 | currency }}</td>
                      <td>{{ contract.interestType }}</td>
                      <td class="text-center">
                        {{ contract.interestRate | percent }}
                      </td>
                      <td>{{ contract.startDate | date: 'dd MMM yyyy' }}</td>
                      <td>{{ contract.endDate | date: 'dd MMM yyyy' }}</td>
                      <td class="text-center">
                        <p-button
                          [routerLink]="[
                            '/customers',
                            contract.userId,
                            'contract',
                            contract.contractId
                          ]"
                          icon="pi pi-search"
                          [rounded]="true"
                          [text]="true"
                          pTooltip="{{
                            'Contracts.ContractDetails' | translate
                          }}"
                          tooltipPosition="left">
                        </p-button>
                        <p-button
                          icon="pi pi-envelope"
                          [rounded]="true"
                          [text]="true"
                          pTooltip="{{ 'Contracts.SendEmail' | translate }}"
                          tooltipPosition="left"
                          (onClick)="
                            onSendContractBalance(contract.contractId)
                          ">
                        </p-button>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="7">
                        {{ 'CustomersInvestments.NotFound' | translate }}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
