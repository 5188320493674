<section>
  <p-toast></p-toast>
  <div class="col-12">
    <div class="flex flex-column sm:flex-row align-items-center gap-4">
      <div class="flex flex-column sm:flex-row align-items-center gap-3">
        @if (!!customerName) {
          <app-avatar
            [displayName]="customerName"
            size="xlarge"
            shape="circle"></app-avatar>
        }
        <div class="flex flex-column align-items-center sm:align-items-start">
          <span class="text-900 font-bold text-4xl">{{ customerName }}</span>
          @if (!!lastLoginDate) {
            <p class="text-600 m-0">
              {{ 'Customers.LastLoginDate' | translate }} {{ lastLoginDate }}
            </p>
          } @else {
            <p class="text-600 m-0">
              {{ 'Customers.NoLastLoginDate' | translate }}
            </p>
          }
        </div>
      </div>
    </div>
  </div>
  <p-tabView>
    <p-tabPanel header="{{ 'FinancialAdvisors.Portfolio' | translate }}">
      <div class="grid grid-nogutter flex-wrap gap-3 p-fluid">
        <div class="col-12 lg:col-12">
          @if (!!financialAdvisorId) {
            <app-customers-investments
              [isFaSection]="true"
              [financialAdvisorId]="financialAdvisorId">
            </app-customers-investments>
          }
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{ 'Customers.PersonalInformation' | translate }}">
      <div class="grid flex-wrap p-fluid">
        <div class="w-full pt-3">
          <div
            class="flex flex-column sm:flex-row align-items-start gap-4 mb-3">
            <div class="flex-grow-1">
              <div class="card mb-3">
                <div class="field">
                  <app-edit-customer
                    [customerId]="customerId"
                    [customer]="customer"
                    [isFinancialAdvisorForm]="true"
                    [commissionsFaDetails]="commissionDetails"
                    (nameChanged)="handleNameChanged($event)">
                  </app-edit-customer>
                </div>
              </div>
            </div>
            <div class="p-0 col-12 lg:col-3">
              <div class="card mb-3">
                @if (customer) {
                  <app-documents-list
                    [documents]="customer.documents"
                    (deleteDocumentRequest)="deleteDocumentService($event)"
                    (requestDownloadDocument)="
                      downloadDocumentService(
                        $event.documentId,
                        $event.filename || undefined
                      )
                    ">
                  </app-documents-list>
                }
              </div>
              <div class="card mb-3">
                @if (financialAdvisorId) {
                  <app-financial-advisor-document-upload
                    [advisorId]="financialAdvisorId"
                    (documentsUploaded)="onDocumentsUploaded()">
                  </app-financial-advisor-document-upload>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</section>
