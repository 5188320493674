import {
  Component,
  EventEmitter,
  Output,
  OnInit,
  inject,
  OnDestroy,
  ViewChild
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared/shared.module';
import { FinancialAdvisorService } from '@app/data/amn-api/services';
import { Subscription } from 'rxjs';
import { ROLE_FINANCIAL_ADVISOR } from '@app/data/models';
import { AlertsService } from '@app/core/services';
import { ErrorService } from '@app/core/services/error.service';
import { SelectItem } from 'primeng/api';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { AmnSingleFileUploadComponent } from '@app/shared/components';

@Component({
  standalone: true,
  selector: 'app-add-financial-advisor',
  imports: [PrimeNgModule, TranslateModule, SharedModule],
  templateUrl: './add-financial-advisor.component.html',
  styleUrls: ['./add-financial-advisor.component.scss']
})
export class AddFinancialAdvisorComponent implements OnInit, OnDestroy {
  private fb = inject(FormBuilder);
  private financialAdvisorService = inject(FinancialAdvisorService);
  private alertsService = inject(AlertsService);
  private subscription = new Subscription();
  private errorService = inject(ErrorService);

  @ViewChild('amnFileUploader') fileUploader!: AmnSingleFileUploadComponent;
  @Output() addFinancialAdvisor = new EventEmitter<void>();

  visible: boolean = false;
  financialAdvisorForm!: FormGroup;
  officesOpts: SelectItem[] = [{ label: '', value: '' }];
  selectedFile!: Blob | null;

  ngOnInit(): void {
    this.selectedFile = null;
    this.financialAdvisorForm = this.fb.group({
      name: new FormControl<string>('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      lastName: new FormControl<string>('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      email: new FormControl<string>('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(50)
      ]),
      phone: new FormControl<string>('', [
        Validators.required,
        Validators.maxLength(20)
      ]),
      commission: new FormControl<string>('', [
        Validators.required,
        Validators.maxLength(20)
      ]),
      marketing: new FormControl<string>('', [
        Validators.required,
        Validators.maxLength(20)
      ]),
      officeId: new FormControl<number | null>(null, [
        Validators.required,
        Validators.maxLength(20)
      ])
    });
    this.getOffices();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onAddClick(): void {
    this.visible = true;
    this.financialAdvisorForm.reset();
  }

  onCancelClick(): void {
    this.visible = false;
    this.fileUploader.clearFileUpload();
    this.financialAdvisorForm.reset();
  }

  handleSetOffice($event: DropdownChangeEvent) {
    if ($event.value) {
      this.financialAdvisorForm.get('officeId')?.setValue(String($event.value));
    }
  }

  getOffices() {
    this.subscription.add(
      this.financialAdvisorService.getOfficesAsync().subscribe({
        next: (response) => {
          if (response.data?.length) {
            this.officesOpts = [];
            response.data?.forEach((office) => {
              this.officesOpts.push({
                label: office.name ?? 'N/A',
                value: String(office.id) ?? '1'
              });
            });
          }
        }
      })
    );
  }

  onUpload(file: Blob | null) {
    if (file !== null) {
      this.selectedFile = file;
    } else {
      this.selectedFile = null;
    }
  }

  onSubmit(): void {
    if (this.financialAdvisorForm.valid) {
      const newAdvisorInfo = {
        ...this.financialAdvisorForm.value,
        photo: this.selectedFile,
        roles: [ROLE_FINANCIAL_ADVISOR]
      };

      this.subscription.add(
        this.financialAdvisorService
          .createFinancialAdvisorAsync({ body: newAdvisorInfo })
          .subscribe({
            next: () => {
              this.alertsService.addSuccess(
                'Alerts.FinancialAdvisorAdded',
                'Alerts.FinancialAdvisorAddedCorrectly',
                true
              );
              this.onCancelClick();
              this.addFinancialAdvisor.emit();
            },
            error: (error: unknown) => {
              this.errorService.handleError(error, {
                badRequest: 'Alerts.ErrorCreatingFinancialAdvisor'
              });
            }
          })
      );
    }
  }
}
