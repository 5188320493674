<div class="grid">
  <div class="col-12">
    <div class="card">
      <h3>{{ 'Menu.Settings' | translate }}</h3>
      <p-table
        #dt
        [value]="selectedCategory ? filteredSettings : settings"
        [lazy]="true"
        (onLazyLoad)="onLazyLoad($event)"
        [totalRecords]="totalRecords"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        responsiveLayout="scroll"
        currentPageReportTemplate="{{ 'Common.PaginatorTemplate' | translate }}"
        [rowsPerPageOptions]="[10, 25, 50]"
        [globalFilterFields]="['name', 'description', 'category']"
        [scrollable]="true"
        styleClass="mt-3">
        <ng-template pTemplate="caption">
          <div
            class="flex flex-wrap gap-2 align-items-center justify-content-between">
            <span
              class="p-input-icon-left w-full sm:w-25rem flex-order-1 sm:flex-order-0">
              <i class="pi pi-search"></i>
              <input
                pInputText
                type="text"
                [(ngModel)]="searchCriteria"
                (input)="onGlobalFilter(dt, $event)"
                placeholder="{{ 'Settings.Search' | translate }}"
                class="w-full" />
            </span>
            <div>
              <app-amn-dropdown
                class="mr-1"
                [placeholder]="translateService.instant('Common.Category')"
                [options]="categoriesOptions"
                field="categoriesOptions.label"
                (filterApplied)="filterCallback($event)"
                [descriptionPrefix]="'Settings.CategoryTypes.'">
              </app-amn-dropdown>
              <app-modal-settings (addSettings)="onAddSetting()" />
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="id" class="text-center" style="width: 10%">
              Id<p-sortIcon field="id"></p-sortIcon>
            </th>
            <th pSortableColumn="name" style="width: 25%">
              {{ 'Common.Name' | translate
              }}<p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="description" style="width: 23%">
              {{ 'Common.Description' | translate
              }}<p-sortIcon field="description"></p-sortIcon>
            </th>
            <th pSortableColumn="value" class="text-center" style="width: 12%">
              {{ 'Common.Value' | translate
              }}<p-sortIcon field="value"></p-sortIcon>
            </th>
            <th
              pSortableColumn="category"
              class="text-center"
              style="width: 10%">
              {{ 'Common.Category' | translate
              }}<p-sortIcon field="category"></p-sortIcon>
            </th>
            <th style="width: 10%"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-setting>
          <tr>
            <td class="text-center">{{ setting.id }}</td>
            <td class="font-bold">{{ setting.name }}</td>
            <td>{{ setting.description }}</td>
            <td class="text-center">{{ setting.value }}</td>
            <td class="text-center">
              <span
                [class]="
                  'setting-badge category-' + setting.category | lowercase
                ">
                {{
                  'Settings.CategoryTypes.' + setting.category | translate
                }}</span
              >
            </td>
            <td>
              <app-modal-settings
                (addSettings)="onAddSetting()"
                [settingId]="setting.id" />
              <p-button
                (click)="requestDeleteSetting(setting.id, $event)"
                icon="pi pi-times"
                [rounded]="true"
                [text]="true"
                severity="danger"
                pTooltip="{{ 'Common.Delete' | translate }}"
                tooltipPosition="left">
              </p-button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">{{ 'Settings.NotFound' | translate }}</td>
          </tr>
        </ng-template>
      </p-table>
      <p-confirmDialog></p-confirmDialog>
    </div>
  </div>
</div>
