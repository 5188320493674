import { Component, Input } from '@angular/core';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-show-expense-note',
  standalone: true,
  imports: [PrimeNgModule, TranslateModule],
  templateUrl: './show-expense-note.component.html',
  styleUrl: './show-expense-note.component.scss'
})
export class ShowExpenseNoteComponent {
  @Input() notes: string = '';
}
