<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<p-fileUpload
  #fileUploader
  name="demo[]"
  accept=".pdf,.png,.jpg,.jpeg"
  (onUpload)="onUpload()"
  [customUpload]="true"
  [multiple]="false"
  (onSelect)="onFileSelected($event)"
  [showUploadButton]="false"
  [showCancelButton]="false"
  [auto]="true"
  class="w-full"
  [maxFileSize]="1000000"
  [disabled]="disabled">
  <ng-template pTemplate="content">
    @if (disabled) {
      <div class="w-full py-4">
        <div
          class="h-full flex flex-column justify-content-center align-items-center"
          pTooltip="{{ 'Alerts.CompleteRequired' | translate }}"
          tooltipPosition="bottom">
          <i class="font-medium pi pi-upload text-900 text-2xl mb-3"></i>
          <span class="font-medium text-900 text-xl mb-3">{{
            'Common.UploadFile' | translate
          }}</span>
          <span class="font-medium text-600 text-md text-center">{{
            'Common.DropOrSelectFile' | translate
          }}</span>
        </div>
      </div>
    } @else {
      <div
        class="w-full py-4 p-cursor-copy"
        (click)="fileUploader.advancedFileInput.nativeElement.click()">
        @if (!selectedFile) {
          <div
            class="h-full flex flex-column justify-content-center align-items-center">
            <i class="pi pi-upload text-900 text-2xl mb-3"></i>
            <span class="font-bold text-900 text-xl mb-3">{{
              'Common.UploadFile' | translate
            }}</span>
            <span class="font-medium text-600 text-md text-center">{{
              'Common.DropOrSelectFile' | translate
            }}</span>
          </div>
        }
        @if (selectedFile) {
          <div
            class="gap-5 flex flex-column justify-content-center align-items-center">
            <div
              class="h-full relative w-7rem h-7rem border-3 border-transparent border-round hover:bg-primary transition-duration-100 cursor-auto p-1"
              (mouseenter)="onImageMouseOver(selectedFile)"
              (mouseleave)="onImageMouseLeave(selectedFile)">
              @if (selectedFile.objectURL) {
                <img
                  [src]="selectedFile.objectURL"
                  [alt]="selectedFile.name"
                  class="w-full h-full border-round shadow-2"
                  title="file" />
              } @else {
                <div class="w-full h-full">
                  <i class="pi pi-file-pdf text-center p-1 text-8xl"></i>
                  <span class="file-name">{{ selectedFile.name }}</span>
                </div>
              }
              <button
                [id]="selectedFile.name"
                #buttonEl
                pButton
                pRipple
                type="button"
                icon="pi pi-times"
                class="p-button-rounded p-button-primary text-sm absolute justify-content-center align-items-center cursor-pointer p-file-remove"
                title="remove"
                (click)="removeImage($event)"></button>
            </div>
          </div>
        }
      </div>
    }
  </ng-template>
</p-fileUpload>
