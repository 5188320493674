import {
  Component,
  inject,
  Input,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { SharedModule } from '@app/shared/shared.module';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { EditCustomerComponent } from '../../../customers/components/edit-customer/edit-customer.component';
import { FinancialAdvisorService } from '@app/data/amn-api/services';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { FinancialAdvisorCustomService } from '@app/shared/custom-services/financial-advisor/financial-advisor-custom.service';
import { EditFinancialAdvisorCommand } from '@app/data/amn-api/models';

@Component({
  selector: 'app-financial-advisor-form',
  standalone: true,
  imports: [PrimeNgModule, SharedModule, EditCustomerComponent],
  templateUrl: './financial-advisor-form.component.html',
  styleUrl: './financial-advisor-form.component.scss'
})
export class FinancialAdvisorFormComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() isMainForm = true;
  @Input() isEditing: boolean = false;
  @Input() isSendingForm = false;

  private subscription = new Subscription();
  financialAdvisorId = 0;
  officesOpts: SelectItem[] = [{ label: '', value: '' }];
  financialAdvisorForm!: FormGroup;
  submitLabel: string = 'Common.Add';
  commissionsFaDetails: EditFinancialAdvisorCommand = {
    id: 0,
    commission: 0,
    marketing: 0,
    officeId: 0
  };

  private route = inject(ActivatedRoute);
  private fb = inject(FormBuilder);
  private financialAdvisorService = inject(FinancialAdvisorService);
  private financialAdvisorCustomService = inject(FinancialAdvisorCustomService);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isEditing']) {
      this.isEditing = changes['isEditing'].currentValue;
      setTimeout(() => {
        this.setEditForm();
      });
    }

    if (changes['isSendingForm']) {
      if (changes['isSendingForm'].currentValue) {
        this.updateOrInsert();
      }
    }
  }

  ngOnInit(): void {
    this.financialAdvisorId = Number(
      this.route.snapshot.paramMap.get('financialAdvisorId')
    );
    this.initForm();
    this.getOffices();
    this.setEditForm();

    this.subscription.add(
      this.financialAdvisorCustomService.getFinancialAdvisorDetails.subscribe({
        next: (values) => {
          this.commissionsFaDetails = {
            id: this.financialAdvisorId,
            commission: values.commission,
            marketing: values.marketing,
            officeId: values.officeId
          };
          this.financialAdvisorForm
            .get('commission')
            ?.setValue(values.commission);
          this.financialAdvisorForm
            .get('marketing')
            ?.setValue(values.marketing);
          this.financialAdvisorForm
            .get('officeId')
            ?.setValue(String(values.officeId));
          this.submitLabel = 'Common.Save';
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initForm() {
    this.financialAdvisorForm = this.fb.group({
      id: new FormControl<number>(this.financialAdvisorId),
      commission: new FormControl<number>(0),
      officeId: new FormControl<string>('0'),
      marketing: new FormControl<number>(0)
    });
  }

  handleSetOffice($event: DropdownChangeEvent) {
    if ($event.value) {
      this.financialAdvisorForm.get('officeId')?.setValue(String($event.value));
    }
  }

  private setEditForm() {
    if (this.isEditing) {
      this.financialAdvisorForm.enable();
    } else {
      this.financialAdvisorForm.disable();
    }
  }

  onCancelClick() {
    this.isEditing = false;
    this.setEditForm();
    if (this.commissionsFaDetails)
      this.financialAdvisorForm.reset(this.commissionsFaDetails);
    else this.financialAdvisorForm.reset();
  }

  onEditClick() {
    this.isEditing = !this.isEditing;
    this.setEditForm();
  }

  updateOrInsert() {
    const payload = {
      id: this.financialAdvisorForm.get('id')?.value,
      body: this.financialAdvisorForm.value
    };
    this.subscription.add(
      this.financialAdvisorService
        .editFinancialAdvisorAsync(payload)
        .subscribe({
          next: (response) => console.log(response)
        })
    );
  }

  getOffices() {
    this.subscription.add(
      this.financialAdvisorService.getOfficesAsync().subscribe({
        next: (response) => {
          if (response.data?.length) {
            this.officesOpts = [];
            response.data?.forEach((office) => {
              this.officesOpts.push({
                label: office.name ?? 'N/A',
                value: String(office.id) ?? '1'
              });
            });
          }
        }
      })
    );
  }
}
