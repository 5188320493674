import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { first } from 'rxjs';
import { AlertsService } from '@app/core/services/alerts.service';
import { ConfirmationService, FilterMatchMode, PrimeIcons } from 'primeng/api';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SettingDto, SettingDtoSearchResponse } from '@app/data/amn-api/models';
import { SettingsService } from '@app/data/amn-api/services';
import { ModalSettingsComponent } from '../../components/modal-settings/modal-settings.component';
import { SharedModule } from '@app/shared/shared.module';
import { getSeverityById } from '@app/core/helpers/roles.helper';
import { ErrorService } from '@app/core/services/error.service';

@Component({
  standalone: true,
  selector: 'app-settings',
  imports: [
    PrimeNgModule,
    ModalSettingsComponent,
    CommonModule,
    TranslateModule,
    FormsModule,
    SharedModule
  ],
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements AfterViewInit, OnInit {
  @ViewChild('dt') table!: Table;

  settings!: SettingDto[];
  filteredSettings!: SettingDto[];
  totalRecords!: number;
  isLoadingSettings: boolean = false;

  top: number = 10;
  skip: number = 0;
  searchCriteria: string = '';
  sortBy: string = '';
  sortDesc: boolean = false;
  backClicked: boolean = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  categoriesOptions: any[] = [
    { label: 'GLOBAL', value: 'GLOBAL' },
    { label: 'CONTRACT', value: 'CONTRACT' },
    { label: 'CUSTOMER', value: 'CUSTOMER' }
  ];

  selectedCategory: string | null = null;

  constructor(
    private settingService: SettingsService,
    private alertsService: AlertsService,
    private confirmationService: ConfirmationService,
    public translateService: TranslateService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    const searchData = localStorage.getItem('searchTermsSetting');
    this.searchCriteria = searchData ? searchData : '';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.searchCriteria) {
        this.table.filterGlobal(this.searchCriteria, FilterMatchMode.CONTAINS);
      }
    });
  }

  requestDeleteSetting(settingId: number, event: Event): void {
    const message = this.translateService.instant('Settings.DeleteConfirm');
    const header = this.translateService.instant('Common.ConfirmationDelete');
    const accept = this.translateService.instant('Common.Accept');
    const cancel = this.translateService.instant('Common.Cancel');

    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: message,
      header: header,
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-secondary p-button-outlined',
      rejectButtonStyleClass: 'p-button-primary p-button-outlined',
      acceptIcon: PrimeIcons.CHECK + ' mr-2',
      rejectIcon: PrimeIcons.TIMES + ' mr-2',
      acceptLabel: accept,
      rejectLabel: cancel,

      accept: () => {
        this.deleteSettingById(settingId);
      }
    });
  }

  filterCallback(selectedCategory: string): void {
    this.selectedCategory = selectedCategory;
    this.updateFilteredTransactions();
  }

  updateFilteredTransactions(): void {
    if (this.selectedCategory) {
      this.filteredSettings = this.settings.filter(
        (settings) => settings.category === this.selectedCategory
      );
    } else {
      this.filteredSettings = [...this.settings];
    }
  }

  onLazyLoad(event: TableLazyLoadEvent) {
    if (event.sortField !== undefined || event.globalFilter !== undefined) {
      this.top = event.rows ?? this.top;
      this.skip = event.first ?? this.skip;
      this.searchCriteria =
        (event.globalFilter as string) ?? this.searchCriteria;
      this.sortBy = (event.sortField as string) ?? this.sortBy;
      this.sortDesc = event.sortOrder === -1;

      localStorage.setItem(
        'settingsFilter',
        JSON.stringify({
          top: this.top,
          skip: this.skip,
          searchCriteria: this.searchCriteria,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc
        })
      );
      this.loadSettings();
    }
  }

  getSeverityByRoleId = getSeverityById;

  onGlobalFilter(table: Table, event: Event) {
    const searchTerm = (event.target as HTMLInputElement).value as string;
    this.getSearchTermsSetting(searchTerm);
    table.filterGlobal(this.searchCriteria, FilterMatchMode.CONTAINS);
  }

  loadSettings(): void {
    this.settingService
      .searchSettings({
        SearchText: this.searchCriteria,
        Top: this.top,
        Skip: this.skip,
        SortBy: this.sortBy,
        SortDescending: this.sortDesc
      })
      .pipe(first())
      .subscribe({
        next: (value: SettingDtoSearchResponse) => {
          if (!value.data) {
            this.alertsService.addWarning(
              'Alerts.CustomersNotFound',
              'Alerts.SettingsNotFoundDesc',
              true
            );
          } else {
            this.settings = value.data;
            this.totalRecords = value.totalRecords ?? 0;
          }
        },
        error: (error: unknown) => {
          this.errorService.handleError(error);
        }
      });
  }

  onAddSetting(): void {
    this.loadSettings();
  }

  getSearchTermsSetting(searchTerm: string | null) {
    if (!!searchTerm && searchTerm !== '') {
      this.searchCriteria = searchTerm;
      localStorage.setItem('searchTermsSetting', this.searchCriteria);
    } else {
      this.searchCriteria = '';
      localStorage.removeItem('searchTermsSetting');
    }
  }

  deleteSettingById(SettingId: number): void {
    this.settingService.deleteSettingAsync({ id: SettingId }).subscribe({
      next: () => {
        this.settings = this.settings?.filter(
          (sett: SettingDto) => sett.id !== SettingId
        );
        this.loadSettings();
        this.alertsService.addSuccess(
          'Alerts.Success',
          'Alerts.DocumentDeletedSuccessfully',
          true
        );
      },
      error: (error: unknown) => {
        this.errorService.handleError(error, {
          badRequest: 'Alerts.DocumentDeletedError'
        });
      }
    });
  }
}
