/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertsService } from '@app/core/services';
import {
  CustomerBeneficiariesDto,
  CustomerBeneficiariesDtoApiResponse
} from '@app/data/amn-api/models';
import { CustomerService } from '@app/data/amn-api/services';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { first } from 'rxjs';
import { SharedModule } from '@app/shared/shared.module';
import { ErrorService } from '@app/core/services/error.service';

@Component({
  selector: 'app-customer-beneficiary-form',
  standalone: true,
  imports: [PrimeNgModule, SharedModule, TranslateModule],
  templateUrl: './customer-beneficiary-form.component.html',
  styleUrl: './customer-beneficiary-form.component.scss'
})
export class CustomerBeneficiaryFormComponent {
  @Input() isEditing: boolean = false;
  @Input() beneficiaryId: number = 0;
  @Input() customerId: number = 0;
  isOtherRelationship: boolean = false;
  @Output() cancelBeneficiary = new EventEmitter<void>();
  @Output() submitBeneficiary = new EventEmitter<void>();
  beneficiaryDetails!: CustomerBeneficiariesDto;
  visible = false;
  relationshipType: SelectItem[] = [
    { label: 'Husband', value: 'HUSBAND' },
    { label: 'Wife', value: 'WIFE' },
    { label: 'Son', value: 'SON' },
    { label: 'Daughter', value: 'DAUGHTER' },
    { label: 'Brother', value: 'BROTHER' },
    { label: 'Sister', value: 'SISTER' },
    { label: 'Father', value: 'FATHER' },
    { label: 'Mother', value: 'MOTHER' },
    { label: 'Grandfather', value: 'GRANDFATHER' },
    { label: 'Grandmother', value: 'GRANDMOTHER' },
    { label: 'Aunt', value: 'AUNT' },
    { label: 'Uncle', value: 'UNCLE' },
    { label: 'Cousin', value: 'COUSIN' },
    { label: 'Other', value: 'OTHER' }
  ];

  ObjectId?: string = '';
  submitLabel: string = 'Common.Add';
  private initialFormValues: any = null;

  beneficiaryForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private customerBeneficiariesService: CustomerService,
    private alertsService: AlertsService,
    private errorService: ErrorService
  ) {
    this.initializeForm();
  }

  get isNew(): boolean {
    return this.beneficiaryId === 0 || this.beneficiaryId === undefined;
  }

  initializeForm() {
    this.beneficiaryForm = this.fb.group({
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      percentage: [
        0,
        [Validators.required, Validators.min(0), Validators.max(100)]
      ],
      relationship: ['', Validators.required],
      otherRelationship: [''],
      isPrincipal: [false]
    });

    this.beneficiaryForm
      .get('relationship')
      ?.valueChanges.subscribe((value) => {
        this.isOtherRelationship = value === 'OTHER';
        if (!this.isOtherRelationship) {
          this.beneficiaryForm.get('otherRelationship')?.setValue('');
        }
      });
  }

  public loadBeneficiaryDetails(
    customerId: number,
    beneficiaryId: number
  ): void {
    setTimeout(() => {
      this.customerBeneficiariesService
        .getCustomerBeneficiaryAsync({
          id: customerId,
          beneficiaryId: beneficiaryId
        })
        .pipe(first())
        .subscribe({
          next: (value: CustomerBeneficiariesDtoApiResponse) => {
            if (value && value.data) {
              this.beneficiaryDetails = value.data;
            }
            this.setInitialFormValues();
          },
          error: (error: unknown) => {
            this.errorService.handleError(error);
          }
        });
    }, 100);
  }

  private setInitialFormValues() {
    this.beneficiaryForm.setValue({
      name: this.beneficiaryDetails.name,
      lastName: this.beneficiaryDetails.lastName,
      percentage: this.beneficiaryDetails.percentage
        ? this.beneficiaryDetails.percentage * 100
        : 0,
      relationship: this.beneficiaryDetails.relationship,
      otherRelationship: this.beneficiaryDetails.otherRelationship,
      isPrincipal: this.beneficiaryDetails.isPrincipal
    });
    this.initialFormValues = this.beneficiaryForm.value;
  }

  onCancelClick() {
    if (!this.isNew) {
      if (this.initialFormValues) {
        this.beneficiaryForm.setValue(this.initialFormValues);
      }
    } else {
      this.cleanForm();
    }
    this.cancelBeneficiary.emit();
  }

  public cleanForm() {
    this.beneficiaryForm.reset();
  }

  updateOrInsert() {
    if (this.beneficiaryForm?.valid) {
      const percentage = this.beneficiaryForm.get('percentage')?.value
        ? this.beneficiaryForm.get('percentage')?.value / 100
        : 0;
      const beneficiaryData = {
        ...this.beneficiaryForm.value,
        name: this.beneficiaryForm.value.name,
        lastName: this.beneficiaryForm.value.lastName,
        percentage: Number(percentage),
        relationship: this.beneficiaryForm.value.relationship,
        otherRelationship: this.beneficiaryForm.value.otherRelationship,
        isPrincipal: this.beneficiaryForm.value.isPrincipal ?? false
      };
      if (this.isNew) {
        this.customerBeneficiariesService
          .createCustomerBeneficiaryAsync({
            id: this.customerId,
            body: beneficiaryData
          })
          .pipe(first())
          .subscribe({
            next: () => {
              this.alertsService.addSuccess(
                'Alerts.SettingAdded',
                'Alerts.SettingAddedDesc',
                true
              );
              this.submitBeneficiary.emit();
              this.beneficiaryForm.enable();
            },
            error: (error: unknown) => {
              this.errorService.handleError(error, {
                badRequest: 'Alerts.ErrorCreatingSetting'
              });
            }
          });
      } else {
        this.customerBeneficiariesService
          .editCustomerBeneficiaryAsync({
            id: this.customerId,
            beneficiaryId: this.beneficiaryId,
            body: beneficiaryData
          })
          .pipe(first())
          .subscribe({
            next: () => {
              this.alertsService.addSuccess(
                'Alerts.SettingEdited',
                'Alerts.SettingEditedDesc',
                true
              );
              this.submitBeneficiary.emit();
            },
            error: (error: unknown) => {
              this.errorService.handleError(error, {
                badRequest: 'Alerts.ErrorEditingSetting'
              });
            }
          });
      }
    }
  }
}
