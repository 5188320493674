import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ErrorService } from '@app/core/services/error.service';
import { UserDtoSearchResponse } from '@app/data/amn-api/models';
import { HealthCheckService, UserService } from '@app/data/amn-api/services';
import { AvatarComponent } from '@app/shared/components';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { AlertsService } from '@core/services';
import { TranslateModule } from '@ngx-translate/core';
import { SelectItem, SharedModule } from 'primeng/api';
import { Subject, first, takeUntil } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-playground',
  imports: [
    TranslateModule,
    PrimeNgModule,
    AvatarComponent,
    SharedModule,
    FormsModule
  ],
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.scss']
})
export class PlaygroundComponent implements OnInit, OnDestroy {
  timeChanged = new EventEmitter<string>();
  unsubscribe$ = new Subject<void>();

  constructor(
    private alertsService: AlertsService,
    private healthCheckService: HealthCheckService,
    private userService: UserService,
    private errorService: ErrorService
  ) {}

  exceptionType: number = 0;
  exceptionTypes: SelectItem[] = [
    { label: 'Failure', value: 501 },
    { label: 'Unexpected', value: 500 },
    { label: 'Validation', value: 400 },
    { label: 'Conflict', value: 409 },
    { label: 'Not Found', value: 404 },
    { label: 'Unauthorized (WARNING!)', value: 401 },
    { label: 'Forbidden', value: 403 }
  ];

  validationNumber: number = 0;
  validationFlag: boolean | undefined;
  validationMessage: string | undefined;

  multipleErrors: boolean = false;

  ngOnInit(): void {
    this.emitTime(); // Emit time immediately on initialization

    // Emit time every second
    setInterval(() => {
      this.emitTime();
    }, 1000);
  }

  //#region Alerts

  showSuccess(): void {
    this.alertsService.addSuccess('Yeah!', 'Good job!', false);
  }

  showInfo(): void {
    this.alertsService.addInfo('Ok', 'Seems good', false);
  }

  showWarn(): void {
    this.alertsService.addWarning('Warning!', 'Wait... is it working?', false);
  }

  showError(): void {
    this.alertsService.addError(
      'Error',
      'Oops... Something goes wrong!',
      false
    );
  }

  //#endregion

  //#region Subscriptions

  emitTime(): void {
    const currentTime = new Date().toLocaleTimeString();
    this.timeChanged.emit(currentTime);
  }

  subscribe(): void {
    this.timeChanged.subscribe((e) => {
      console.log('🚀 ~ subscribe ~ e:', e);
    });
  }

  subscribeModule(): void {
    this.timeChanged.pipe(takeUntil(this.unsubscribe$)).subscribe((e) => {
      console.log('🚀 ~ subscribeModule ~ e:', e);
    });
  }

  subscribeFirst(): void {
    this.timeChanged.pipe(first()).subscribe((e) => {
      console.log('🚀 ~ subscribeFirst ~ e:', e);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  //#endregion

  //#region HealthChecks

  checkApi(): void {
    this.healthCheckService
      .checkApiWorkingAsync()
      .pipe(first())
      .subscribe((res) => {
        this.alertsService.addSuccess(
          'Cool',
          res.data?.toString() ?? '',
          false
        );
      });
  }

  getUsers(): void {
    this.userService
      .searchUsersAsync({})
      .pipe(first())
      .subscribe({
        next: (value: UserDtoSearchResponse) => {
          console.table(value.data);
          this.alertsService.addSuccess(
            'Users',
            'Users searched correctly',
            false
          );
        },
        error: (error: unknown) => {
          this.errorService.handleError(error);
        }
      });
  }

  checkErrors(): void {
    this.healthCheckService
      .checkErrorAsync({ Multiple: this.multipleErrors })
      .pipe(first())
      .subscribe({
        error: (error: unknown) => {
          this.errorService.handleError(error);
        }
      });
  }

  checkExceptions(): void {
    this.healthCheckService
      .checkExceptionAsync({ Type: this.exceptionType })
      .pipe(first())
      .subscribe({
        error: (error: unknown) => {
          this.errorService.handleError(error);
        }
      });
  }

  checkValidations(): void {
    this.healthCheckService
      .checkValidationsAsync({
        Number: this.validationNumber,
        Flag: this.validationFlag,
        Message: this.validationMessage
      })
      .pipe(first())
      .subscribe({
        error: (error: unknown) => {
          this.errorService.handleError(error);
        }
      });
  }

  //#endregion
}
