/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MyFxBookDailyGainDollarDto,
  MyFxBookDailyGainDollarDtoSearchResponse
} from '@app/data/amn-api/models';
import { MyFxBookService } from '@app/data/amn-api/services';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);
@Component({
  selector: 'app-dashboard-chart-daily-gains',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    PrimeNgModule,
    FormsModule,
    SharedModule
  ],
  providers: [DatePipe, CurrencyPipe],
  templateUrl: './dashboard-chart-daily-gains.component.html',
  styleUrls: ['./dashboard-chart-daily-gains.component.scss']
})
export class DashboardChartDailyGainsComponent implements OnInit {
  rangeDates: Date[] | undefined;
  lineData: any;
  lineOptions: any;
  startDate: string = '';
  endDate: string = '';
  plugins = [ChartDataLabels];

  constructor(
    private myFxBookService: MyFxBookService,
    public translateService: TranslateService,
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.setupChartOptions();
    this.fetchDailyGainsData();
  }

  fetchDailyGainsData() {
    this.myFxBookService
      .searchMyFxBookDailyGains({
        StartDate: this.startDate,
        EndDate: this.endDate
      })
      .subscribe((response: MyFxBookDailyGainDollarDtoSearchResponse) => {
        if (response && response.data) {
          const labels = response.data.map((gain: MyFxBookDailyGainDollarDto) =>
            this.datePipe.transform(gain.date, 'yyyy-MM-dd')
          );

          const profitAmount = response.data.map(
            (gain: MyFxBookDailyGainDollarDto) => gain.profitUSD
          );

          this.lineData = {
            labels: labels,
            datasets: [
              {
                fill: false,
                backgroundColor: getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary-500'),
                yAxisID: 'y',
                tension: 0.4,
                data: profitAmount
              }
            ]
          };
        }
      });
  }

  onDateRangeChange() {
    if (this.rangeDates && this.rangeDates[0] && this.rangeDates[1]) {
      this.startDate = this.rangeDates[0].toISOString().split('T')[0];
      this.endDate = this.rangeDates[1].toISOString().split('T')[0];
      this.fetchDailyGainsData();
    } else {
      console.warn('Seleccione un rango de fechas completo.');
    }
  }

  setupChartOptions() {
    this.lineOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          beginAtZero: true
        },
        y: {
          beginAtZero: true,
          ticks: {
            callback: (value: number) =>
              this.currencyPipe.transform(value, 'USD')
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: (context: any) =>
              this.currencyPipe.transform(context.raw, 'USD')
          }
        },
        datalabels: {
          display: true,
          align: 'top',
          formatter: (value: number) =>
            this.currencyPipe.transform(value, 'USD')
        }
      }
    };
  }
}
