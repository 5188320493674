/* tslint:disable */
/* eslint-disable */
export enum DocumentContainer {
  Customers = 'CUSTOMERS',
  Contracts = 'CONTRACTS',
  Transactions = 'TRANSACTIONS',
  Expenses = 'EXPENSES',
  CustomersPhotos = 'CUSTOMERS_PHOTOS',
  FinancialAdvisors = 'FINANCIAL_ADVISORS'
}
