import { Injectable } from '@angular/core';
import { EditFinancialAdvisorCommand } from '@app/data/amn-api/models';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FinancialAdvisorCustomService {
  financialAdvisorDetails$ = new Subject<EditFinancialAdvisorCommand>();

  constructor() {}

  setFinancialAdvisorDetails(details: EditFinancialAdvisorCommand) {
    this.financialAdvisorDetails$.next(details);
  }

  get getFinancialAdvisorDetails() {
    return this.financialAdvisorDetails$.asObservable();
  }
}
