import { Injectable } from '@angular/core';
import { DocumentService } from '@app/data/amn-api/services';
import { AlertsService } from '@app/core/services';
import { ErrorService } from '@app/core/services/error.service';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentManagementService {
  constructor(
    private documentService: DocumentService,
    private alertsService: AlertsService,
    private errorService: ErrorService
  ) {}

  deleteDocumentById(documentId: number, onSuccess: () => void) {
    this.documentService
      .deleteDocumentAsync({ id: documentId })
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.alertsService.addSuccess(
            'Alerts.DocumentDeletedTitle',
            'Alerts.DocumentDeletedSuccessfully',
            true
          );
          onSuccess();
        },
        error: (error: unknown) => {
          this.errorService.handleError(error, {
            badRequest: 'Alerts.DocumentDeletedErrorTitle'
          });
        }
      });
  }

  downloadDocumentById(documentId: number, fileName?: string) {
    this.documentService
      .downloadDocumentAsync({ id: documentId })
      .pipe(take(1))
      .subscribe({
        next: (response) => {
          const blob = response;
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = fileName || `document-${documentId}`;
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);

          window.URL.revokeObjectURL(url);

          this.alertsService.addSuccess(
            'Alerts.DocumentDownloadedTitle',
            'Alerts.DocumentDownloadedSuccessfully',
            true
          );
        },
        error: (error: unknown) => {
          this.errorService.handleError(error, {
            badRequest: 'Alerts.DocumentDownloadErrorTitle'
          });
        }
      });
  }
}
