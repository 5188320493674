<div class="flex w-full">
  <div class="col-12 md:col-6 xl:col-4 pb-0">
    <div
      class="card h-full flex flex-column align-items-center justify-content-center">
      <i class="pi pi-dollar text-primary text-4xl mb-4"></i>
      <span class="text-xl mb-4 font-medium">{{
        'CustomersInvestments.TotalInvestmentSummary' | translate
      }}</span>
      @if (customersTotals) {
        <div class="w-full text-center">
          <span class="text-2xl text-primary font-bold"
            >USD {{ customersTotals.totalInvestmentUSD ?? 0 | currency }}</span
          >
          <p-divider type="solid" />
          <span class="text-2xl text-primary font-bold"
            >MXN {{ customersTotals.totalInvestmentMXN ?? 0 | currency }}</span
          >
        </div>
      }
    </div>
  </div>
  <div class="col-12 md:col-6 xl:col-4 pb-0">
    <div
      class="card h-full flex flex-column align-items-center justify-content-center">
      <i class="pi pi-chart-line text-primary text-4xl mb-4"></i>
      <span class="text-xl mb-4 font-medium">{{
        'CustomersInvestments.AccruedInterestSummary' | translate
      }}</span>
      @if (customersTotals) {
        <div class="w-full text-center">
          <span class="text-2xl text-primary font-bold"
            >USD {{ customersTotals.accruedInterestUSD ?? 0 | currency }}</span
          >
          <p-divider type="solid" />
          <span class="text-2xl text-primary font-bold"
            >MXN {{ customersTotals.accruedInterestMXN ?? 0 | currency }}</span
          >
        </div>
      }
    </div>
  </div>
  <div class="col-12 md:col-6 xl:col-4 pb-0">
    <div
      class="card h-full flex flex-column align-items-center justify-content-center">
      <i class="pi pi-money-bill text-primary text-4xl mb-4"></i>
      <span class="text-xl mb-4 font-medium">{{
        'CustomersInvestments.TotalDebtOwedSummary' | translate
      }}</span>
      @if (customersTotals) {
        <div class="w-full text-center">
          <span class="text-2xl text-primary font-bold"
            >USD {{ customersTotals.totalDebtOwedUSD ?? 0 | currency }}</span
          >
          <p-divider type="solid" />
          <span class="text-2xl text-primary font-bold"
            >MXN {{ customersTotals.totalDebtOwedMXN ?? 0 | currency }}</span
          >
        </div>
      }
    </div>
  </div>
</div>
