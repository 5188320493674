import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService, ProfileService } from '@app/core/services';
import { ContractService } from '@app/data/amn-api/services';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { first } from 'rxjs';
import { TransactionsComponent } from '../../components/transactions/transactions.component';
import { TranslateService } from '@ngx-translate/core';
import { ContractDocumentUploadComponent } from '../../components/contract-document-upload/contract-document-upload.component';
import { ContractDocumentTimelineComponent } from '../../components/contract-document-timeline/contract-document-timeline.component';
import { ConfirmationService, PrimeIcons, SelectItem } from 'primeng/api';
import { SharedModule } from '@app/shared/shared.module';
import { Table, TableService } from 'primeng/table';
import { AddDepositContractComponent } from '../../components/add-deposit-contract/add-deposit-contract.component';
import {
  ContractDetailsDto,
  ContractDetailsDtoApiResponse,
  TransactionDto,
  TransactionType
} from '@app/data/amn-api/models';
import { DocumentsListComponent } from '@app/shared/components/documents-list/documents-list.component';
import { ContractStatus } from '@app/data/amn-api/models/contract-status';
import { ErrorService } from '@app/core/services/error.service';
import { DocumentManagementService } from '@app/core/services/document.service';
import { EditContractFormComponent } from '../../components/edit-contract-form/edit-contract-form.component';

@Component({
  standalone: true,
  selector: 'app-contract',
  imports: [
    PrimeNgModule,
    EditContractFormComponent,
    ContractDocumentTimelineComponent,
    ContractDocumentUploadComponent,
    DocumentsListComponent,
    AddDepositContractComponent,
    TransactionsComponent,
    SharedModule
  ],
  providers: [Table, TableService],
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent implements OnInit {
  contractId: number = 0;
  customerId: number = 0;
  userId: number | null = 0;
  userIsAdmin: boolean = false;
  transactions!: TransactionDto[];
  filteredTransactions!: TransactionDto[];
  contractDetails!: ContractDetailsDto;
  showCancelBtn: boolean = false;
  showSendBalanceEmailBtn: boolean = false;

  transactionTypes!: SelectItem[];
  selectedStatus: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private contractService: ContractService,
    private alertsService: AlertsService,
    private profileService: ProfileService,
    public translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private errorService: ErrorService,
    private documentManagementService: DocumentManagementService,
    private router: Router
  ) {
    this.transactionTypes = [];
    for (const type in TransactionType) {
      if (isNaN(Number(type))) {
        this.transactionTypes.push({
          label: type.toUpperCase(),
          value: type.toUpperCase()
        });
      }
    }
  }

  ngOnInit() {
    this.route.paramMap.pipe(first()).subscribe((params) => {
      const idParam = params.get('contractId');
      const customerIdParam = params.get('customerId');
      this.contractId = idParam ? +idParam : 0;
      this.customerId = customerIdParam ? +customerIdParam : 0;
      this.loadContractDetails(this.contractId);
    });

    this.profileService.userLoaded
      .pipe(first())
      .subscribe((loaded: boolean) => {
        this.userId = loaded ? this.profileService.userId : 0;
        this.userIsAdmin = loaded ? this.profileService.isAdmin : false;
      });
  }

  filterCallback(selectedStatus: string): void {
    this.selectedStatus = selectedStatus;
    this.updateFilteredTransactions();
  }

  updateFilteredTransactions(): void {
    if (this.selectedStatus) {
      this.filteredTransactions = this.transactions.filter(
        (transaction) => transaction.transactionType === this.selectedStatus
      );
    } else {
      this.filteredTransactions = [...this.transactions]; // Copy all contracts if there is no filter
    }
  }

  loadContractDetails(contractId: number): void {
    setTimeout(() => {
      this.contractService
        .getContractAsync({ id: contractId })
        .pipe(first())
        .subscribe({
          next: (contract: ContractDetailsDtoApiResponse) => {
            if (contract && contract.data) {
              this.contractDetails = contract.data;
              this.showCancelBtn = false; // Reset showCancelBtn to default value
              if (this.contractDetails && this.contractDetails.transactions) {
                this.transactions = this.contractDetails.transactions;
              }
              // Set showCancelBtn if the current status is not cancelled or completed and the current user is admin
              if (
                this.contractDetails &&
                this.contractDetails.status !== ContractStatus.Cancelled &&
                this.contractDetails.status !== ContractStatus.Completed &&
                this.userIsAdmin
              ) {
                this.showCancelBtn = true;
              }
              if (
                this.contractDetails &&
                (this.contractDetails.status == ContractStatus.Active ||
                  this.contractDetails.status == ContractStatus.Completed)
              ) {
                this.showSendBalanceEmailBtn = true;
              }
            }
          },
          error: (error: unknown) => {
            this.errorService.handleError(error);
            this.router.navigate(['/dashboard']);
          }
        });
    }, 100);
  }

  deleteDocumentService(documentId: number) {
    this.documentManagementService.deleteDocumentById(documentId, () => {
      this.loadContractDetails(this.contractId);
    });
  }

  onDocumentsUploaded() {
    this.loadContractDetails(this.contractId);
  }

  downloadDocumentService(documentId: number, fileName?: string) {
    this.documentManagementService.downloadDocumentById(documentId, fileName);
  }

  onAddDeposit(): void {
    this.loadContractDetails(this.contractId);
  }

  onDownloadDraft(): void {
    this.alertsService.addInfo(
      'Alerts.DownloadingContractDraft',
      'Alerts.ContractDraftDownloaded',
      true
    );

    this.contractService
      .downloadContractDraft({ id: this.contractId })
      .pipe(first())
      .subscribe({
        next: (response) => {
          const filename = `Contract-${this.contractDetails.contractNumber?.replaceAll(' ', '')}`;

          const blob = response;
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = filename;
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);

          this.loadContractDetails(this.contractId);

          window.URL.revokeObjectURL(url);
        },
        error: (error: unknown) => {
          this.errorService.handleError(error);
        }
      });
  }

  requestCancelContract(contractId: number, event: Event): void {
    const message = this.translateService.instant(
      'Contracts.CancelConfirmTitle'
    );
    const header = this.translateService.instant('Contracts.CancelConfirm');
    const accept = this.translateService.instant('Common.Accept');
    const cancel = this.translateService.instant('Common.Cancel');

    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: message,
      header: header,
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-secondary p-button-outlined',
      rejectButtonStyleClass: 'p-button-primary p-button-outlined',
      acceptIcon: PrimeIcons.CHECK + ' mr-2',
      rejectIcon: PrimeIcons.TIMES + ' mr-2',
      acceptLabel: accept,
      rejectLabel: cancel,

      accept: () => {
        this.contractService.cancelContractAsync({ id: contractId }).subscribe({
          next: () => {
            this.loadContractDetails(this.contractId);
            this.alertsService.addSuccess(
              'Contracts.Cancel',
              'Contracts.CancelSuccess',
              true
            );
          },
          error: () => {
            this.alertsService.addError(
              'Contracts.Cancel',
              'Contracts.CancelError',
              true
            );
          }
        });
      }
    });
  }

  onSendContractBalance(): void {
    this.alertsService.addInfo(
      'Alerts.SendingBalance',
      'Alerts.SendingBalanceDescription',
      true
    );
    this.contractService
      .sendCustomerContractBalance({ id: this.contractId })
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertsService.addSuccess(
            'Alerts.SendContractBalanceSuccess',
            'Alerts.SendContractBalanceSuccessDescription',
            true
          );
        },
        error: (error: unknown) => {
          this.errorService.handleError(error);
        }
      });
  }
}
