/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BankDtoApiResponse } from '../models/bank-dto-api-response';
import { ContractDetailsDtoApiResponse } from '../models/contract-details-dto-api-response';
import { createBankAsync } from '../fn/customer/create-bank-async';
import { CreateBankAsync$Params } from '../fn/customer/create-bank-async';
import { createBankAsync$Plain } from '../fn/customer/create-bank-async-plain';
import { CreateBankAsync$Plain$Params } from '../fn/customer/create-bank-async-plain';
import { createCustomerBeneficiaryAsync } from '../fn/customer/create-customer-beneficiary-async';
import { CreateCustomerBeneficiaryAsync$Params } from '../fn/customer/create-customer-beneficiary-async';
import { createCustomerBeneficiaryAsync$Plain } from '../fn/customer/create-customer-beneficiary-async-plain';
import { CreateCustomerBeneficiaryAsync$Plain$Params } from '../fn/customer/create-customer-beneficiary-async-plain';
import { createTaxRegimeCustomerAsync } from '../fn/customer/create-tax-regime-customer-async';
import { CreateTaxRegimeCustomerAsync$Params } from '../fn/customer/create-tax-regime-customer-async';
import { createTaxRegimeCustomerAsync$Plain } from '../fn/customer/create-tax-regime-customer-async-plain';
import { CreateTaxRegimeCustomerAsync$Plain$Params } from '../fn/customer/create-tax-regime-customer-async-plain';
import { CustomerBeneficiariesDtoApiResponse } from '../models/customer-beneficiaries-dto-api-response';
import { CustomerDtoSearchResponse } from '../models/customer-dto-search-response';
import { deleteBankAsync } from '../fn/customer/delete-bank-async';
import { DeleteBankAsync$Params } from '../fn/customer/delete-bank-async';
import { deleteBankAsync$Plain } from '../fn/customer/delete-bank-async-plain';
import { DeleteBankAsync$Plain$Params } from '../fn/customer/delete-bank-async-plain';
import { deleteCustomerBeneficiaryAsync } from '../fn/customer/delete-customer-beneficiary-async';
import { DeleteCustomerBeneficiaryAsync$Params } from '../fn/customer/delete-customer-beneficiary-async';
import { deleteCustomerBeneficiaryAsync$Plain } from '../fn/customer/delete-customer-beneficiary-async-plain';
import { DeleteCustomerBeneficiaryAsync$Plain$Params } from '../fn/customer/delete-customer-beneficiary-async-plain';
import { DocumentDtoListApiResponse } from '../models/document-dto-list-api-response';
import { editBankAsync } from '../fn/customer/edit-bank-async';
import { EditBankAsync$Params } from '../fn/customer/edit-bank-async';
import { editBankAsync$Plain } from '../fn/customer/edit-bank-async-plain';
import { EditBankAsync$Plain$Params } from '../fn/customer/edit-bank-async-plain';
import { editCustomerBeneficiaryAsync } from '../fn/customer/edit-customer-beneficiary-async';
import { EditCustomerBeneficiaryAsync$Params } from '../fn/customer/edit-customer-beneficiary-async';
import { editCustomerBeneficiaryAsync$Plain } from '../fn/customer/edit-customer-beneficiary-async-plain';
import { EditCustomerBeneficiaryAsync$Plain$Params } from '../fn/customer/edit-customer-beneficiary-async-plain';
import { getCustomerBeneficiaryAsync } from '../fn/customer/get-customer-beneficiary-async';
import { GetCustomerBeneficiaryAsync$Params } from '../fn/customer/get-customer-beneficiary-async';
import { getCustomerBeneficiaryAsync$Plain } from '../fn/customer/get-customer-beneficiary-async-plain';
import { GetCustomerBeneficiaryAsync$Plain$Params } from '../fn/customer/get-customer-beneficiary-async-plain';
import { searchCustomers } from '../fn/customer/search-customers';
import { SearchCustomers$Params } from '../fn/customer/search-customers';
import { searchCustomers$Plain } from '../fn/customer/search-customers-plain';
import { SearchCustomers$Plain$Params } from '../fn/customer/search-customers-plain';
import { SuccessApiResponse } from '../models/success-api-response';
import { updateStatusCustomerBankAsync } from '../fn/customer/update-status-customer-bank-async';
import { UpdateStatusCustomerBankAsync$Params } from '../fn/customer/update-status-customer-bank-async';
import { updateStatusCustomerBankAsync$Plain } from '../fn/customer/update-status-customer-bank-async-plain';
import { UpdateStatusCustomerBankAsync$Plain$Params } from '../fn/customer/update-status-customer-bank-async-plain';
import { uploadCustomerDocumentAsync } from '../fn/customer/upload-customer-document-async';
import { UploadCustomerDocumentAsync$Params } from '../fn/customer/upload-customer-document-async';
import { uploadCustomerDocumentAsync$Plain } from '../fn/customer/upload-customer-document-async-plain';
import { UploadCustomerDocumentAsync$Plain$Params } from '../fn/customer/upload-customer-document-async-plain';

@Injectable({ providedIn: 'root' })
export class CustomerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `searchCustomers()` */
  static readonly SearchCustomersPath = '/api/customer';

  /**
   * Search customers by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchCustomers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchCustomers$Plain$Response(params?: SearchCustomers$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerDtoSearchResponse>> {
    return searchCustomers$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search customers by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchCustomers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchCustomers$Plain(params?: SearchCustomers$Plain$Params, context?: HttpContext): Observable<CustomerDtoSearchResponse> {
    return this.searchCustomers$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerDtoSearchResponse>): CustomerDtoSearchResponse => r.body)
    );
  }

  /**
   * Search customers by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchCustomers$Response(params?: SearchCustomers$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerDtoSearchResponse>> {
    return searchCustomers(this.http, this.rootUrl, params, context);
  }

  /**
   * Search customers by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchCustomers(params?: SearchCustomers$Params, context?: HttpContext): Observable<CustomerDtoSearchResponse> {
    return this.searchCustomers$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerDtoSearchResponse>): CustomerDtoSearchResponse => r.body)
    );
  }

  /** Path part for operation `uploadCustomerDocumentAsync()` */
  static readonly UploadCustomerDocumentAsyncPath = '/api/customer/{id}/document';

  /**
   * Upload contract document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadCustomerDocumentAsync$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadCustomerDocumentAsync$Plain$Response(params: UploadCustomerDocumentAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDtoListApiResponse>> {
    return uploadCustomerDocumentAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload contract document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadCustomerDocumentAsync$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadCustomerDocumentAsync$Plain(params: UploadCustomerDocumentAsync$Plain$Params, context?: HttpContext): Observable<DocumentDtoListApiResponse> {
    return this.uploadCustomerDocumentAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDtoListApiResponse>): DocumentDtoListApiResponse => r.body)
    );
  }

  /**
   * Upload contract document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadCustomerDocumentAsync()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadCustomerDocumentAsync$Response(params: UploadCustomerDocumentAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDtoListApiResponse>> {
    return uploadCustomerDocumentAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload contract document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadCustomerDocumentAsync$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadCustomerDocumentAsync(params: UploadCustomerDocumentAsync$Params, context?: HttpContext): Observable<DocumentDtoListApiResponse> {
    return this.uploadCustomerDocumentAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDtoListApiResponse>): DocumentDtoListApiResponse => r.body)
    );
  }

  /** Path part for operation `createBankAsync()` */
  static readonly CreateBankAsyncPath = '/api/customer/{id}/bank';

  /**
   * Create bank to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBankAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBankAsync$Plain$Response(params: CreateBankAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BankDtoApiResponse>> {
    return createBankAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Create bank to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBankAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBankAsync$Plain(params: CreateBankAsync$Plain$Params, context?: HttpContext): Observable<BankDtoApiResponse> {
    return this.createBankAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BankDtoApiResponse>): BankDtoApiResponse => r.body)
    );
  }

  /**
   * Create bank to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBankAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBankAsync$Response(params: CreateBankAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<BankDtoApiResponse>> {
    return createBankAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Create bank to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBankAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBankAsync(params: CreateBankAsync$Params, context?: HttpContext): Observable<BankDtoApiResponse> {
    return this.createBankAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<BankDtoApiResponse>): BankDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `editBankAsync()` */
  static readonly EditBankAsyncPath = '/api/customer/{id}/bank/{bankId}';

  /**
   * Edit bank to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editBankAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editBankAsync$Plain$Response(params: EditBankAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<BankDtoApiResponse>> {
    return editBankAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit bank to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editBankAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editBankAsync$Plain(params: EditBankAsync$Plain$Params, context?: HttpContext): Observable<BankDtoApiResponse> {
    return this.editBankAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<BankDtoApiResponse>): BankDtoApiResponse => r.body)
    );
  }

  /**
   * Edit bank to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editBankAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editBankAsync$Response(params: EditBankAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<BankDtoApiResponse>> {
    return editBankAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit bank to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editBankAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editBankAsync(params: EditBankAsync$Params, context?: HttpContext): Observable<BankDtoApiResponse> {
    return this.editBankAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<BankDtoApiResponse>): BankDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `deleteBankAsync()` */
  static readonly DeleteBankAsyncPath = '/api/customer/{id}/bank/{bankId}';

  /**
   * Delete bank to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBankAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBankAsync$Plain$Response(params: DeleteBankAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDetailsDtoApiResponse>> {
    return deleteBankAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete bank to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBankAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBankAsync$Plain(params: DeleteBankAsync$Plain$Params, context?: HttpContext): Observable<ContractDetailsDtoApiResponse> {
    return this.deleteBankAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDetailsDtoApiResponse>): ContractDetailsDtoApiResponse => r.body)
    );
  }

  /**
   * Delete bank to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBankAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBankAsync$Response(params: DeleteBankAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDetailsDtoApiResponse>> {
    return deleteBankAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete bank to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBankAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBankAsync(params: DeleteBankAsync$Params, context?: HttpContext): Observable<ContractDetailsDtoApiResponse> {
    return this.deleteBankAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDetailsDtoApiResponse>): ContractDetailsDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `updateStatusCustomerBankAsync()` */
  static readonly UpdateStatusCustomerBankAsyncPath = '/api/customer/{id}/bank/{bankId}/status/{status}';

  /**
   * Change status customer bank.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateStatusCustomerBankAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateStatusCustomerBankAsync$Plain$Response(params: UpdateStatusCustomerBankAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDetailsDtoApiResponse>> {
    return updateStatusCustomerBankAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Change status customer bank.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateStatusCustomerBankAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateStatusCustomerBankAsync$Plain(params: UpdateStatusCustomerBankAsync$Plain$Params, context?: HttpContext): Observable<ContractDetailsDtoApiResponse> {
    return this.updateStatusCustomerBankAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDetailsDtoApiResponse>): ContractDetailsDtoApiResponse => r.body)
    );
  }

  /**
   * Change status customer bank.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateStatusCustomerBankAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateStatusCustomerBankAsync$Response(params: UpdateStatusCustomerBankAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDetailsDtoApiResponse>> {
    return updateStatusCustomerBankAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Change status customer bank.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateStatusCustomerBankAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateStatusCustomerBankAsync(params: UpdateStatusCustomerBankAsync$Params, context?: HttpContext): Observable<ContractDetailsDtoApiResponse> {
    return this.updateStatusCustomerBankAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDetailsDtoApiResponse>): ContractDetailsDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `createTaxRegimeCustomerAsync()` */
  static readonly CreateTaxRegimeCustomerAsyncPath = '/api/customer/{id}/taxregime';

  /**
   * Create tax regime to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTaxRegimeCustomerAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTaxRegimeCustomerAsync$Plain$Response(params: CreateTaxRegimeCustomerAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDetailsDtoApiResponse>> {
    return createTaxRegimeCustomerAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Create tax regime to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTaxRegimeCustomerAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTaxRegimeCustomerAsync$Plain(params: CreateTaxRegimeCustomerAsync$Plain$Params, context?: HttpContext): Observable<ContractDetailsDtoApiResponse> {
    return this.createTaxRegimeCustomerAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDetailsDtoApiResponse>): ContractDetailsDtoApiResponse => r.body)
    );
  }

  /**
   * Create tax regime to customer.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTaxRegimeCustomerAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTaxRegimeCustomerAsync$Response(params: CreateTaxRegimeCustomerAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<ContractDetailsDtoApiResponse>> {
    return createTaxRegimeCustomerAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Create tax regime to customer.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTaxRegimeCustomerAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTaxRegimeCustomerAsync(params: CreateTaxRegimeCustomerAsync$Params, context?: HttpContext): Observable<ContractDetailsDtoApiResponse> {
    return this.createTaxRegimeCustomerAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContractDetailsDtoApiResponse>): ContractDetailsDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `createCustomerBeneficiaryAsync()` */
  static readonly CreateCustomerBeneficiaryAsyncPath = '/api/customer/{id}/beneficiary';

  /**
   * Create Customer Beneficiary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCustomerBeneficiaryAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCustomerBeneficiaryAsync$Plain$Response(params: CreateCustomerBeneficiaryAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>> {
    return createCustomerBeneficiaryAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Customer Beneficiary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCustomerBeneficiaryAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCustomerBeneficiaryAsync$Plain(params: CreateCustomerBeneficiaryAsync$Plain$Params, context?: HttpContext): Observable<CustomerBeneficiariesDtoApiResponse> {
    return this.createCustomerBeneficiaryAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>): CustomerBeneficiariesDtoApiResponse => r.body)
    );
  }

  /**
   * Create Customer Beneficiary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCustomerBeneficiaryAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCustomerBeneficiaryAsync$Response(params: CreateCustomerBeneficiaryAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>> {
    return createCustomerBeneficiaryAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Customer Beneficiary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCustomerBeneficiaryAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCustomerBeneficiaryAsync(params: CreateCustomerBeneficiaryAsync$Params, context?: HttpContext): Observable<CustomerBeneficiariesDtoApiResponse> {
    return this.createCustomerBeneficiaryAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>): CustomerBeneficiariesDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `getCustomerBeneficiaryAsync()` */
  static readonly GetCustomerBeneficiaryAsyncPath = '/api/customer/{id}/beneficiary/{beneficiaryId}';

  /**
   * Get Customer Beneficiary by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerBeneficiaryAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBeneficiaryAsync$Plain$Response(params: GetCustomerBeneficiaryAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>> {
    return getCustomerBeneficiaryAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Customer Beneficiary by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerBeneficiaryAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBeneficiaryAsync$Plain(params: GetCustomerBeneficiaryAsync$Plain$Params, context?: HttpContext): Observable<CustomerBeneficiariesDtoApiResponse> {
    return this.getCustomerBeneficiaryAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>): CustomerBeneficiariesDtoApiResponse => r.body)
    );
  }

  /**
   * Get Customer Beneficiary by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerBeneficiaryAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBeneficiaryAsync$Response(params: GetCustomerBeneficiaryAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>> {
    return getCustomerBeneficiaryAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Customer Beneficiary by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerBeneficiaryAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBeneficiaryAsync(params: GetCustomerBeneficiaryAsync$Params, context?: HttpContext): Observable<CustomerBeneficiariesDtoApiResponse> {
    return this.getCustomerBeneficiaryAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>): CustomerBeneficiariesDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `editCustomerBeneficiaryAsync()` */
  static readonly EditCustomerBeneficiaryAsyncPath = '/api/customer/{id}/beneficiary/{beneficiaryId}';

  /**
   * Edit Customer Beneficiary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editCustomerBeneficiaryAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editCustomerBeneficiaryAsync$Plain$Response(params: EditCustomerBeneficiaryAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>> {
    return editCustomerBeneficiaryAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit Customer Beneficiary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editCustomerBeneficiaryAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editCustomerBeneficiaryAsync$Plain(params: EditCustomerBeneficiaryAsync$Plain$Params, context?: HttpContext): Observable<CustomerBeneficiariesDtoApiResponse> {
    return this.editCustomerBeneficiaryAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>): CustomerBeneficiariesDtoApiResponse => r.body)
    );
  }

  /**
   * Edit Customer Beneficiary.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editCustomerBeneficiaryAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editCustomerBeneficiaryAsync$Response(params: EditCustomerBeneficiaryAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>> {
    return editCustomerBeneficiaryAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit Customer Beneficiary.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editCustomerBeneficiaryAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editCustomerBeneficiaryAsync(params: EditCustomerBeneficiaryAsync$Params, context?: HttpContext): Observable<CustomerBeneficiariesDtoApiResponse> {
    return this.editCustomerBeneficiaryAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>): CustomerBeneficiariesDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `deleteCustomerBeneficiaryAsync()` */
  static readonly DeleteCustomerBeneficiaryAsyncPath = '/api/customer/{id}/beneficiary/{beneficiaryId}';

  /**
   * Delete Customer Beneficiary by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerBeneficiaryAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerBeneficiaryAsync$Plain$Response(params: DeleteCustomerBeneficiaryAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return deleteCustomerBeneficiaryAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Customer Beneficiary by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerBeneficiaryAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerBeneficiaryAsync$Plain(params: DeleteCustomerBeneficiaryAsync$Plain$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.deleteCustomerBeneficiaryAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

  /**
   * Delete Customer Beneficiary by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerBeneficiaryAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerBeneficiaryAsync$Response(params: DeleteCustomerBeneficiaryAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return deleteCustomerBeneficiaryAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Customer Beneficiary by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerBeneficiaryAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerBeneficiaryAsync(params: DeleteCustomerBeneficiaryAsync$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.deleteCustomerBeneficiaryAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

}
