/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createExpenseAsync } from '../fn/expense/create-expense-async';
import { CreateExpenseAsync$Params } from '../fn/expense/create-expense-async';
import { createExpenseAsync$Plain } from '../fn/expense/create-expense-async-plain';
import { CreateExpenseAsync$Plain$Params } from '../fn/expense/create-expense-async-plain';
import { deleteExpenseAsync } from '../fn/expense/delete-expense-async';
import { DeleteExpenseAsync$Params } from '../fn/expense/delete-expense-async';
import { deleteExpenseAsync$Plain } from '../fn/expense/delete-expense-async-plain';
import { DeleteExpenseAsync$Plain$Params } from '../fn/expense/delete-expense-async-plain';
import { DocumentDtoListApiResponse } from '../models/document-dto-list-api-response';
import { editExpenseAsync } from '../fn/expense/edit-expense-async';
import { EditExpenseAsync$Params } from '../fn/expense/edit-expense-async';
import { editExpenseAsync$Plain } from '../fn/expense/edit-expense-async-plain';
import { EditExpenseAsync$Plain$Params } from '../fn/expense/edit-expense-async-plain';
import { ExpenseDtoApiResponse } from '../models/expense-dto-api-response';
import { ExpenseDtoSearchResponse } from '../models/expense-dto-search-response';
import { getExpenseAsync } from '../fn/expense/get-expense-async';
import { GetExpenseAsync$Params } from '../fn/expense/get-expense-async';
import { getExpenseAsync$Plain } from '../fn/expense/get-expense-async-plain';
import { GetExpenseAsync$Plain$Params } from '../fn/expense/get-expense-async-plain';
import { Int32ApiResponse } from '../models/int-32-api-response';
import { searchExpenseQuery } from '../fn/expense/search-expense-query';
import { SearchExpenseQuery$Params } from '../fn/expense/search-expense-query';
import { searchExpenseQuery$Plain } from '../fn/expense/search-expense-query-plain';
import { SearchExpenseQuery$Plain$Params } from '../fn/expense/search-expense-query-plain';
import { SuccessApiResponse } from '../models/success-api-response';
import { uploadExpenseDocumentAsync } from '../fn/expense/upload-expense-document-async';
import { UploadExpenseDocumentAsync$Params } from '../fn/expense/upload-expense-document-async';
import { uploadExpenseDocumentAsync$Plain } from '../fn/expense/upload-expense-document-async-plain';
import { UploadExpenseDocumentAsync$Plain$Params } from '../fn/expense/upload-expense-document-async-plain';

@Injectable({ providedIn: 'root' })
export class ExpenseService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `searchExpenseQuery()` */
  static readonly SearchExpenseQueryPath = '/api/expense';

  /**
   * Search Expenses by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchExpenseQuery$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchExpenseQuery$Plain$Response(params?: SearchExpenseQuery$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExpenseDtoSearchResponse>> {
    return searchExpenseQuery$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Expenses by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchExpenseQuery$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchExpenseQuery$Plain(params?: SearchExpenseQuery$Plain$Params, context?: HttpContext): Observable<ExpenseDtoSearchResponse> {
    return this.searchExpenseQuery$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExpenseDtoSearchResponse>): ExpenseDtoSearchResponse => r.body)
    );
  }

  /**
   * Search Expenses by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchExpenseQuery()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchExpenseQuery$Response(params?: SearchExpenseQuery$Params, context?: HttpContext): Observable<StrictHttpResponse<ExpenseDtoSearchResponse>> {
    return searchExpenseQuery(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Expenses by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchExpenseQuery$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchExpenseQuery(params?: SearchExpenseQuery$Params, context?: HttpContext): Observable<ExpenseDtoSearchResponse> {
    return this.searchExpenseQuery$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExpenseDtoSearchResponse>): ExpenseDtoSearchResponse => r.body)
    );
  }

  /** Path part for operation `createExpenseAsync()` */
  static readonly CreateExpenseAsyncPath = '/api/expense';

  /**
   * Create Expense with document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createExpenseAsync$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createExpenseAsync$Plain$Response(params?: CreateExpenseAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Int32ApiResponse>> {
    return createExpenseAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Expense with document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createExpenseAsync$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createExpenseAsync$Plain(params?: CreateExpenseAsync$Plain$Params, context?: HttpContext): Observable<Int32ApiResponse> {
    return this.createExpenseAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Int32ApiResponse>): Int32ApiResponse => r.body)
    );
  }

  /**
   * Create Expense with document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createExpenseAsync()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createExpenseAsync$Response(params?: CreateExpenseAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<Int32ApiResponse>> {
    return createExpenseAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Expense with document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createExpenseAsync$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createExpenseAsync(params?: CreateExpenseAsync$Params, context?: HttpContext): Observable<Int32ApiResponse> {
    return this.createExpenseAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<Int32ApiResponse>): Int32ApiResponse => r.body)
    );
  }

  /** Path part for operation `uploadExpenseDocumentAsync()` */
  static readonly UploadExpenseDocumentAsyncPath = '/api/expense/{id}/document';

  /**
   * Upload expense document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadExpenseDocumentAsync$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadExpenseDocumentAsync$Plain$Response(params: UploadExpenseDocumentAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDtoListApiResponse>> {
    return uploadExpenseDocumentAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload expense document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadExpenseDocumentAsync$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadExpenseDocumentAsync$Plain(params: UploadExpenseDocumentAsync$Plain$Params, context?: HttpContext): Observable<DocumentDtoListApiResponse> {
    return this.uploadExpenseDocumentAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDtoListApiResponse>): DocumentDtoListApiResponse => r.body)
    );
  }

  /**
   * Upload expense document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadExpenseDocumentAsync()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadExpenseDocumentAsync$Response(params: UploadExpenseDocumentAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDtoListApiResponse>> {
    return uploadExpenseDocumentAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload expense document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadExpenseDocumentAsync$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadExpenseDocumentAsync(params: UploadExpenseDocumentAsync$Params, context?: HttpContext): Observable<DocumentDtoListApiResponse> {
    return this.uploadExpenseDocumentAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDtoListApiResponse>): DocumentDtoListApiResponse => r.body)
    );
  }

  /** Path part for operation `getExpenseAsync()` */
  static readonly GetExpenseAsyncPath = '/api/expense/{id}';

  /**
   * Get expense by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExpenseAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExpenseAsync$Plain$Response(params: GetExpenseAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExpenseDtoApiResponse>> {
    return getExpenseAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get expense by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExpenseAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExpenseAsync$Plain(params: GetExpenseAsync$Plain$Params, context?: HttpContext): Observable<ExpenseDtoApiResponse> {
    return this.getExpenseAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExpenseDtoApiResponse>): ExpenseDtoApiResponse => r.body)
    );
  }

  /**
   * Get expense by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExpenseAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExpenseAsync$Response(params: GetExpenseAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<ExpenseDtoApiResponse>> {
    return getExpenseAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Get expense by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExpenseAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExpenseAsync(params: GetExpenseAsync$Params, context?: HttpContext): Observable<ExpenseDtoApiResponse> {
    return this.getExpenseAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExpenseDtoApiResponse>): ExpenseDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `editExpenseAsync()` */
  static readonly EditExpenseAsyncPath = '/api/expense/{id}';

  /**
   * Edit expense.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editExpenseAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editExpenseAsync$Plain$Response(params: EditExpenseAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExpenseDtoApiResponse>> {
    return editExpenseAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit expense.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editExpenseAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editExpenseAsync$Plain(params: EditExpenseAsync$Plain$Params, context?: HttpContext): Observable<ExpenseDtoApiResponse> {
    return this.editExpenseAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExpenseDtoApiResponse>): ExpenseDtoApiResponse => r.body)
    );
  }

  /**
   * Edit expense.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editExpenseAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editExpenseAsync$Response(params: EditExpenseAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<ExpenseDtoApiResponse>> {
    return editExpenseAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit expense.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editExpenseAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editExpenseAsync(params: EditExpenseAsync$Params, context?: HttpContext): Observable<ExpenseDtoApiResponse> {
    return this.editExpenseAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExpenseDtoApiResponse>): ExpenseDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `deleteExpenseAsync()` */
  static readonly DeleteExpenseAsyncPath = '/api/expense/{id}';

  /**
   * Delete expense.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteExpenseAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExpenseAsync$Plain$Response(params: DeleteExpenseAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return deleteExpenseAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete expense.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteExpenseAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExpenseAsync$Plain(params: DeleteExpenseAsync$Plain$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.deleteExpenseAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

  /**
   * Delete expense.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteExpenseAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExpenseAsync$Response(params: DeleteExpenseAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return deleteExpenseAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete expense.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteExpenseAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExpenseAsync(params: DeleteExpenseAsync$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.deleteExpenseAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

}
