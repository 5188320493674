/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertsService } from '@app/core/services';
import { TranslateModule } from '@ngx-translate/core';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { SharedModule } from '@app/shared/shared.module';
import { first } from 'rxjs';
import { SettingDto, SettingDtoApiResponse } from '@app/data/amn-api/models';
import { SettingsService } from '@app/data/amn-api/services';
import { SelectItem } from 'primeng/api';
import { ErrorService } from '@app/core/services/error.service';

@Component({
  standalone: true,
  selector: 'app-settings-form',
  imports: [PrimeNgModule, SharedModule, TranslateModule],
  templateUrl: './settings-form.component.html',
  styleUrls: ['./settings-form.component.scss']
})
export class SettingsFormComponent {
  @Input() settingId: number = 0;
  @Output() cancelSetting = new EventEmitter<void>();
  @Output() submitSetting = new EventEmitter<void>();
  settingDetails!: SettingDto;
  visible = false;
  categoriesOptions: SelectItem[] = [
    { label: 'Global', value: 'GLOBAL' },
    { label: 'Contract', value: 'CONTRACT' },
    { label: 'Customer', value: 'CUSTOMER' }
  ];

  ObjectId?: string = '';
  submitLabel: string = 'Common.Add';
  private initialFormValues: any = null;

  settingForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private settingService: SettingsService,
    private alertsService: AlertsService,
    private errorService: ErrorService
  ) {
    this.initializeForm();
  }

  get isNew(): boolean {
    return this.settingId === 0 || this.settingId === undefined;
  }

  initializeForm() {
    this.settingForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      value: ['', Validators.required],
      category: ['', Validators.required]
    });
  }

  public loadSettingsDetails(settingId: number): void {
    setTimeout(() => {
      this.settingService
        .getSettingAsync({ id: settingId })
        .pipe(first())
        .subscribe({
          next: (value: SettingDtoApiResponse) => {
            if (value && value.data) {
              this.settingDetails = value.data;
            }
            this.setInitialFormValues();
          },
          error: (error: unknown) => {
            this.errorService.handleError(error);
          }
        });
    }, 100);
  }

  private setInitialFormValues() {
    this.settingForm.setValue({
      name: this.settingDetails.name,
      description: this.settingDetails.description,
      value: this.settingDetails.value,
      category: this.settingDetails.category
    });
    this.initialFormValues = this.settingForm.value;
  }

  onCancelClick() {
    if (!this.isNew) {
      if (this.initialFormValues) {
        this.settingForm.setValue(this.initialFormValues);
      }
    } else {
      this.cleanForm();
    }
    this.cancelSetting.emit();
  }

  public cleanForm() {
    this.settingForm.reset({
      category: ''
    });
  }

  hasError(formControl: string, validationName: string): boolean | undefined {
    return (
      this.settingForm.get(formControl)?.hasError(validationName) &&
      this.settingForm.get(formControl)?.touched
    );
  }

  updateOrInsert() {
    if (this.settingForm?.valid) {
      const settingData = {
        ...this.settingForm.value,
        name: this.settingForm.value.name,
        description: this.settingForm.value.description,
        value: this.settingForm.value.value,
        category: this.settingForm.value.category
      };
      if (this.isNew) {
        this.settingService
          .createSettingAsync({ body: settingData })
          .pipe(first())
          .subscribe({
            next: () => {
              this.alertsService.addSuccess(
                'Alerts.SettingAdded',
                'Alerts.SettingAddedDesc',
                true
              );
              this.submitSetting.emit();
              this.settingForm.enable();
            },
            error: (error: unknown) => {
              this.errorService.handleError(error, {
                badRequest: 'Alerts.ErrorCreatingSetting'
              });
            }
          });
      } else {
        this.settingService
          .editSettingAsync({ id: this.settingId, body: settingData })
          .pipe(first())
          .subscribe({
            next: () => {
              this.alertsService.addSuccess(
                'Alerts.SettingEdited',
                'Alerts.SettingEditedDesc',
                true
              );
              this.submitSetting.emit();
            },
            error: (error: unknown) => {
              this.errorService.handleError(error, {
                badRequest: 'Alerts.ErrorEditingSetting'
              });
            }
          });
      }
    }
  }
}
