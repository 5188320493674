import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '@core/services';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authenticationService.renewToken().pipe(
      switchMap((token: string | null) => {
        if (!token) {
          this.authenticationService.logout();
          return next.handle(request);
        }
        const headers = request.clone({
          setHeaders: { Authorization: `Bearer ${token}` }
        });
        return next.handle(headers);
      }),
      catchError((err: any) => {
        if (err?.status === HttpStatusCode.Unauthorized) {
          this.authenticationService.logout();
        }

        return throwError(() => err);
      })
    );
  }
}
