import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { AlertsService } from '@app/core/services';
import { ErrorService } from '@app/core/services/error.service';
import { FinancialAdvisorService } from '@app/data/amn-api/services';
import { AmnFilesUploadComponent } from '@app/shared/components';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { first } from 'rxjs';

@Component({
  selector: 'app-financial-advisor-document-upload',
  standalone: true,
  imports: [
    PrimeNgModule,
    SharedModule,
    TranslateModule,
    CommonModule,
    AmnFilesUploadComponent
  ],
  templateUrl: './financial-advisor-document-upload.component.html',
  styleUrl: './financial-advisor-document-upload.component.scss'
})
export class FinancialAdvisorDocumentUploadComponent {
  @Input() advisorId: number = 0;
  @Output() documentsUploaded = new EventEmitter<void>();

  @ViewChild('amnFilesUploader') fileUploader!: AmnFilesUploadComponent;

  constructor(
    private financialAdvisorService: FinancialAdvisorService,
    private alertsService: AlertsService,
    private errorService: ErrorService
  ) {}

  onUpload(files: Blob[]) {
    if (files && files.length > 0) {
      this.financialAdvisorService
        .uploadFinancialAdvisorsDocumentAsync({
          id: this.advisorId,
          body: { files }
        })
        .pipe(first())
        .subscribe({
          next: () => {
            this.alertsService.addSuccess(
              'Alerts.DocumentsUploaded',
              'Alerts.ContractDocumentsUploadedSuccessfully',
              true
            );
            this.documentsUploaded.emit();
            this.fileUploader.clearFileUpload();
          },
          error: (error: unknown) => {
            this.errorService.handleError(error, {
              badRequest: 'Alerts.ErrorUploadingDocuments'
            });
          }
        });
    }
  }
}
