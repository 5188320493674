<p-button
  icon="pi pi-plus"
  [rounded]="true"
  [text]="true"
  (click)="showDialog()"
  [style.display]="isDisabled ? 'none' : 'block'"
  pTooltip="{{ 'Common.AddNew' | translate }}"
  tooltipPosition="left"></p-button>
<p-dialog
  header="{{ 'TaxRegime.TitleSection' | translate }}"
  [(visible)]="visible"
  [style]="{ width: '60vw', height: '500px' }"
  [modal]="true"
  [draggable]="false"
  (onHide)="handleCancel()">
  <div class="flex justify-content-around align-items-center mb-4">
    <div class="w-10">
      <p-dropdown
        [options]="taxRegimes"
        [(ngModel)]="taxRegimesSelected"
        optionLabel="name"
        [filter]="true"
        filterBy="name"
        [showClear]="true"
        placeholder="{{ 'TaxRegime.SelectTaxRegimes' | translate }}">
        <ng-template pTemplate="selectedItem" let-selectedOption>
          <div class="flex align-items-center gap-2">
            <div>{{ selectedOption.name }}</div>
          </div>
        </ng-template>
        <ng-template let-regime pTemplate="item">
          <div class="flex align-items-center gap-2">
            <div>{{ regime.name }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="w-2 flex justify-content-around align-items-center">
      <p-button icon="pi pi-plus" (onClick)="handleAddTaxRegime()"></p-button>
    </div>
  </div>

  <div class="tbl-modal-height">
    <p-table id="addTaxRegimes" [value]="currentRegimes" class="w-full">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 90%">{{ 'Common.Name' | translate }}</th>
          <th style="width: 10%">{{ 'Common.Actions' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td>{{ rowData.name }}</td>
          <td>
            <i
              class="pi pi-times cursor-pointer text-red-500"
              (click)="handleRemoveTaxRegime(rowData)"
              role="presentation"></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="empty">
        <tr>
          <td colspan="2">No hay documentos registrados</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="w-full flex justify-content-end flex-wrap bottom-0">
    <p-button
      label="{{ 'Common.Cancel' | translate }}"
      icon="pi pi-times"
      class="w-auto mr-3 mt-3"
      [outlined]="true"
      severity="secondary"
      (onClick)="handleCancel()"></p-button>
    <p-button
      type="button"
      label="{{ 'Common.Save' | translate }}"
      icon="pi pi-check"
      class="w-auto mt-3 mr-3"
      [outlined]="true"
      severity="primary"
      (onClick)="handleSubmit()"
      [loading]="isLoading">
    </p-button>
  </div>
</p-dialog>
