import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { CardModule } from 'primeng/card';
import { SlideMenuModule } from 'primeng/slidemenu';
import { TableModule } from 'primeng/table';
import { MenubarModule } from 'primeng/menubar';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { RippleModule } from 'primeng/ripple';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressBarModule } from 'primeng/progressbar';
import { TagModule } from 'primeng/tag';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { BadgeModule } from 'primeng/badge';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { TimelineModule } from 'primeng/timeline';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import { ChipModule } from 'primeng/chip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ChartModule } from 'primeng/chart';
import { AutoCompleteModule } from 'primeng/autocomplete';

const modules = [
  ButtonModule,
  SidebarModule,
  CardModule,
  SlideMenuModule,
  TableModule,
  MenubarModule,
  BlockUIModule,
  ProgressSpinnerModule,
  ToastModule,
  CheckboxModule,
  InputTextModule,
  DialogModule,
  DividerModule,
  RippleModule,
  DropdownModule,
  FileUploadModule,
  InputTextareaModule,
  ProgressBarModule,
  TagModule,
  MessageModule,
  MessagesModule,
  BadgeModule,
  RadioButtonModule,
  InputSwitchModule,
  TooltipModule,
  AvatarModule,
  AvatarGroupModule,
  MultiSelectModule,
  CalendarModule,
  FloatLabelModule,
  InputNumberModule,
  InputMaskModule,
  TimelineModule,
  ConfirmDialogModule,
  ChipModule,
  OverlayPanelModule,
  ChartModule,
  AutoCompleteModule
];

@NgModule({
  declarations: [],
  imports: [...modules],
  exports: [...modules]
})
export class PrimeNgModule {}
