/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FinancialAdvisorDetailsDtoApiResponse } from '../../models/financial-advisor-details-dto-api-response';

export interface GetFinancialAdvisor$Params {
  id: number;
}

export function getFinancialAdvisor(http: HttpClient, rootUrl: string, params: GetFinancialAdvisor$Params, context?: HttpContext): Observable<StrictHttpResponse<FinancialAdvisorDetailsDtoApiResponse>> {
  const rb = new RequestBuilder(rootUrl, getFinancialAdvisor.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<FinancialAdvisorDetailsDtoApiResponse>;
    })
  );
}

getFinancialAdvisor.PATH = '/api/financial-advisor/{id}';
