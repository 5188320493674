<p-button
  (onClick)="op.toggle($event)"
  icon="pi pi-book"
  [rounded]="true"
  [text]="true"
  pTooltip="{{ 'Expenses.SeeNotes' | translate }}"
  tooltipPosition="left">
</p-button>

<p-overlayPanel #op>
  <div class="p-grid">
    <div class="p-col" style="max-width: 300px">
      <p>
        {{ notes }}
      </p>
    </div>
  </div>
</p-overlayPanel>
