import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { CustomerBeneficiariesDto } from '@app/data/amn-api/models';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { SharedModule } from '@app/shared/shared.module';
import { CustomerBeneficiariesModalComponent } from '../customer-beneficiaries-modal/customer-beneficiaries-modal.component';
import { AlertsService } from '@app/core/services';
import { ConfirmationService, PrimeIcons } from 'primeng/api';
import { CustomerService } from '@app/data/amn-api/services';
import { ErrorService } from '@app/core/services/error.service';

@Component({
  selector: 'app-customer-beneficiaries',
  standalone: true,
  imports: [
    PrimeNgModule,
    TranslateModule,
    CommonModule,
    SharedModule,
    CustomerBeneficiariesModalComponent
  ],
  templateUrl: './customer-beneficiaries.component.html',
  styleUrl: './customer-beneficiaries.component.scss'
})
export class CustomerBeneficiariesComponent {
  @Input() beneficiaries!: CustomerBeneficiariesDto[];
  @Output() typeSelected = new EventEmitter<string>();
  @Output() editBeneficiary = new EventEmitter<void>();

  private translateService = inject(TranslateService);
  private confirmationService = inject(ConfirmationService);
  private customerBeneficiaryService = inject(CustomerService);
  private alertsService = inject(AlertsService);
  private errorService = inject(ErrorService);

  clear(table: Table) {
    table.clear();
  }

  onEditBeneficiaryClick(): void {
    this.editBeneficiary.emit();
  }

  requestDeleteBeneficiary(
    customerId: number,
    beneficiaryId: number,
    event: Event
  ): void {
    const message = this.translateService.instant(
      'Beneficiaries.DeleteConfirm'
    );
    const header = this.translateService.instant('Common.ConfirmationDelete');
    const accept = this.translateService.instant('Common.Accept');
    const cancel = this.translateService.instant('Common.Cancel');

    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: message,
      header: header,
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: 'p-button-secondary p-button-outlined',
      rejectButtonStyleClass: 'p-button-primary p-button-outlined',
      acceptIcon: PrimeIcons.CHECK + ' mr-2',
      rejectIcon: PrimeIcons.TIMES + ' mr-2',
      acceptLabel: accept,
      rejectLabel: cancel,

      accept: () => {
        this.deleteBeneficiaryById(customerId, beneficiaryId);
      }
    });
  }

  deleteBeneficiaryById(customerId: number, beneficiaryId: number): void {
    this.customerBeneficiaryService
      .deleteCustomerBeneficiaryAsync({
        id: customerId,
        beneficiaryId: beneficiaryId
      })
      .subscribe({
        next: () => {
          this.editBeneficiary.emit();
          this.alertsService.addSuccess(
            'Alerts.Success',
            'Alerts.BeneficiaryDeletedSuccessfully',
            true
          );
        },
        error: (error: unknown) => {
          this.errorService.handleError(error, {
            badRequest: 'Alerts.BeneficiaryDeletedError'
          });
        }
      });
  }
}
