/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CreatedApiResponse } from '../models/created-api-response';
import { createFinancialAdvisorAsync } from '../fn/financial-advisor/create-financial-advisor-async';
import { CreateFinancialAdvisorAsync$Params } from '../fn/financial-advisor/create-financial-advisor-async';
import { createFinancialAdvisorAsync$Plain } from '../fn/financial-advisor/create-financial-advisor-async-plain';
import { CreateFinancialAdvisorAsync$Plain$Params } from '../fn/financial-advisor/create-financial-advisor-async-plain';
import { DocumentDtoListApiResponse } from '../models/document-dto-list-api-response';
import { editFinancialAdvisorAsync } from '../fn/financial-advisor/edit-financial-advisor-async';
import { EditFinancialAdvisorAsync$Params } from '../fn/financial-advisor/edit-financial-advisor-async';
import { editFinancialAdvisorAsync$Plain } from '../fn/financial-advisor/edit-financial-advisor-async-plain';
import { EditFinancialAdvisorAsync$Plain$Params } from '../fn/financial-advisor/edit-financial-advisor-async-plain';
import { FinancialAdvisorDetailsDtoApiResponse } from '../models/financial-advisor-details-dto-api-response';
import { FinancialAdvisorDtoSearchResponse } from '../models/financial-advisor-dto-search-response';
import { getFinancialAdvisor } from '../fn/financial-advisor/get-financial-advisor';
import { GetFinancialAdvisor$Params } from '../fn/financial-advisor/get-financial-advisor';
import { getFinancialAdvisor$Plain } from '../fn/financial-advisor/get-financial-advisor-plain';
import { GetFinancialAdvisor$Plain$Params } from '../fn/financial-advisor/get-financial-advisor-plain';
import { getOfficesAsync } from '../fn/financial-advisor/get-offices-async';
import { GetOfficesAsync$Params } from '../fn/financial-advisor/get-offices-async';
import { getOfficesAsync$Plain } from '../fn/financial-advisor/get-offices-async-plain';
import { GetOfficesAsync$Plain$Params } from '../fn/financial-advisor/get-offices-async-plain';
import { OfficeListApiResponse } from '../models/office-list-api-response';
import { searchFinancialAdvisors } from '../fn/financial-advisor/search-financial-advisors';
import { SearchFinancialAdvisors$Params } from '../fn/financial-advisor/search-financial-advisors';
import { searchFinancialAdvisors$Plain } from '../fn/financial-advisor/search-financial-advisors-plain';
import { SearchFinancialAdvisors$Plain$Params } from '../fn/financial-advisor/search-financial-advisors-plain';
import { uploadFinancialAdvisorsDocumentAsync } from '../fn/financial-advisor/upload-financial-advisors-document-async';
import { UploadFinancialAdvisorsDocumentAsync$Params } from '../fn/financial-advisor/upload-financial-advisors-document-async';
import { uploadFinancialAdvisorsDocumentAsync$Plain } from '../fn/financial-advisor/upload-financial-advisors-document-async-plain';
import { UploadFinancialAdvisorsDocumentAsync$Plain$Params } from '../fn/financial-advisor/upload-financial-advisors-document-async-plain';

@Injectable({ providedIn: 'root' })
export class FinancialAdvisorService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `searchFinancialAdvisors()` */
  static readonly SearchFinancialAdvisorsPath = '/api/financial-advisor';

  /**
   * Search financial advisors by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchFinancialAdvisors$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchFinancialAdvisors$Plain$Response(params?: SearchFinancialAdvisors$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<FinancialAdvisorDtoSearchResponse>> {
    return searchFinancialAdvisors$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search financial advisors by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchFinancialAdvisors$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchFinancialAdvisors$Plain(params?: SearchFinancialAdvisors$Plain$Params, context?: HttpContext): Observable<FinancialAdvisorDtoSearchResponse> {
    return this.searchFinancialAdvisors$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<FinancialAdvisorDtoSearchResponse>): FinancialAdvisorDtoSearchResponse => r.body)
    );
  }

  /**
   * Search financial advisors by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchFinancialAdvisors()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchFinancialAdvisors$Response(params?: SearchFinancialAdvisors$Params, context?: HttpContext): Observable<StrictHttpResponse<FinancialAdvisorDtoSearchResponse>> {
    return searchFinancialAdvisors(this.http, this.rootUrl, params, context);
  }

  /**
   * Search financial advisors by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchFinancialAdvisors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchFinancialAdvisors(params?: SearchFinancialAdvisors$Params, context?: HttpContext): Observable<FinancialAdvisorDtoSearchResponse> {
    return this.searchFinancialAdvisors$Response(params, context).pipe(
      map((r: StrictHttpResponse<FinancialAdvisorDtoSearchResponse>): FinancialAdvisorDtoSearchResponse => r.body)
    );
  }

  /** Path part for operation `createFinancialAdvisorAsync()` */
  static readonly CreateFinancialAdvisorAsyncPath = '/api/financial-advisor';

  /**
   * Create financial advisor.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFinancialAdvisorAsync$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createFinancialAdvisorAsync$Plain$Response(params?: CreateFinancialAdvisorAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreatedApiResponse>> {
    return createFinancialAdvisorAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Create financial advisor.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createFinancialAdvisorAsync$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createFinancialAdvisorAsync$Plain(params?: CreateFinancialAdvisorAsync$Plain$Params, context?: HttpContext): Observable<CreatedApiResponse> {
    return this.createFinancialAdvisorAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreatedApiResponse>): CreatedApiResponse => r.body)
    );
  }

  /**
   * Create financial advisor.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFinancialAdvisorAsync()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createFinancialAdvisorAsync$Response(params?: CreateFinancialAdvisorAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<CreatedApiResponse>> {
    return createFinancialAdvisorAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Create financial advisor.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createFinancialAdvisorAsync$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  createFinancialAdvisorAsync(params?: CreateFinancialAdvisorAsync$Params, context?: HttpContext): Observable<CreatedApiResponse> {
    return this.createFinancialAdvisorAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreatedApiResponse>): CreatedApiResponse => r.body)
    );
  }

  /** Path part for operation `getFinancialAdvisor()` */
  static readonly GetFinancialAdvisorPath = '/api/financial-advisor/{id}';

  /**
   * Search financial advisors by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancialAdvisor$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancialAdvisor$Plain$Response(params: GetFinancialAdvisor$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<FinancialAdvisorDetailsDtoApiResponse>> {
    return getFinancialAdvisor$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search financial advisors by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancialAdvisor$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancialAdvisor$Plain(params: GetFinancialAdvisor$Plain$Params, context?: HttpContext): Observable<FinancialAdvisorDetailsDtoApiResponse> {
    return this.getFinancialAdvisor$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<FinancialAdvisorDetailsDtoApiResponse>): FinancialAdvisorDetailsDtoApiResponse => r.body)
    );
  }

  /**
   * Search financial advisors by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancialAdvisor()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancialAdvisor$Response(params: GetFinancialAdvisor$Params, context?: HttpContext): Observable<StrictHttpResponse<FinancialAdvisorDetailsDtoApiResponse>> {
    return getFinancialAdvisor(this.http, this.rootUrl, params, context);
  }

  /**
   * Search financial advisors by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancialAdvisor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancialAdvisor(params: GetFinancialAdvisor$Params, context?: HttpContext): Observable<FinancialAdvisorDetailsDtoApiResponse> {
    return this.getFinancialAdvisor$Response(params, context).pipe(
      map((r: StrictHttpResponse<FinancialAdvisorDetailsDtoApiResponse>): FinancialAdvisorDetailsDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `editFinancialAdvisorAsync()` */
  static readonly EditFinancialAdvisorAsyncPath = '/api/financial-advisor/{id}';

  /**
   * Edit financial advisor information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editFinancialAdvisorAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editFinancialAdvisorAsync$Plain$Response(params: EditFinancialAdvisorAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<FinancialAdvisorDetailsDtoApiResponse>> {
    return editFinancialAdvisorAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit financial advisor information.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editFinancialAdvisorAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editFinancialAdvisorAsync$Plain(params: EditFinancialAdvisorAsync$Plain$Params, context?: HttpContext): Observable<FinancialAdvisorDetailsDtoApiResponse> {
    return this.editFinancialAdvisorAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<FinancialAdvisorDetailsDtoApiResponse>): FinancialAdvisorDetailsDtoApiResponse => r.body)
    );
  }

  /**
   * Edit financial advisor information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editFinancialAdvisorAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editFinancialAdvisorAsync$Response(params: EditFinancialAdvisorAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<FinancialAdvisorDetailsDtoApiResponse>> {
    return editFinancialAdvisorAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Edit financial advisor information.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editFinancialAdvisorAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  editFinancialAdvisorAsync(params: EditFinancialAdvisorAsync$Params, context?: HttpContext): Observable<FinancialAdvisorDetailsDtoApiResponse> {
    return this.editFinancialAdvisorAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<FinancialAdvisorDetailsDtoApiResponse>): FinancialAdvisorDetailsDtoApiResponse => r.body)
    );
  }

  /** Path part for operation `getOfficesAsync()` */
  static readonly GetOfficesAsyncPath = '/api/financial-advisor/offices';

  /**
   * Get offices information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOfficesAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOfficesAsync$Plain$Response(params?: GetOfficesAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OfficeListApiResponse>> {
    return getOfficesAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Get offices information.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOfficesAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOfficesAsync$Plain(params?: GetOfficesAsync$Plain$Params, context?: HttpContext): Observable<OfficeListApiResponse> {
    return this.getOfficesAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfficeListApiResponse>): OfficeListApiResponse => r.body)
    );
  }

  /**
   * Get offices information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOfficesAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOfficesAsync$Response(params?: GetOfficesAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<OfficeListApiResponse>> {
    return getOfficesAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Get offices information.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOfficesAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOfficesAsync(params?: GetOfficesAsync$Params, context?: HttpContext): Observable<OfficeListApiResponse> {
    return this.getOfficesAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfficeListApiResponse>): OfficeListApiResponse => r.body)
    );
  }

  /** Path part for operation `uploadFinancialAdvisorsDocumentAsync()` */
  static readonly UploadFinancialAdvisorsDocumentAsyncPath = '/api/financial-advisor/{id}/document';

  /**
   * Upload fiancial advisors document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadFinancialAdvisorsDocumentAsync$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFinancialAdvisorsDocumentAsync$Plain$Response(params: UploadFinancialAdvisorsDocumentAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDtoListApiResponse>> {
    return uploadFinancialAdvisorsDocumentAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload fiancial advisors document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadFinancialAdvisorsDocumentAsync$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFinancialAdvisorsDocumentAsync$Plain(params: UploadFinancialAdvisorsDocumentAsync$Plain$Params, context?: HttpContext): Observable<DocumentDtoListApiResponse> {
    return this.uploadFinancialAdvisorsDocumentAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDtoListApiResponse>): DocumentDtoListApiResponse => r.body)
    );
  }

  /**
   * Upload fiancial advisors document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadFinancialAdvisorsDocumentAsync()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFinancialAdvisorsDocumentAsync$Response(params: UploadFinancialAdvisorsDocumentAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDtoListApiResponse>> {
    return uploadFinancialAdvisorsDocumentAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload fiancial advisors document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadFinancialAdvisorsDocumentAsync$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFinancialAdvisorsDocumentAsync(params: UploadFinancialAdvisorsDocumentAsync$Params, context?: HttpContext): Observable<DocumentDtoListApiResponse> {
    return this.uploadFinancialAdvisorsDocumentAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDtoListApiResponse>): DocumentDtoListApiResponse => r.body)
    );
  }

}
