import { Injectable } from '@angular/core';
import { AlertsService } from './alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(
    private alertsService: AlertsService,
    private translateService: TranslateService
  ) {}

  /**
   * Handle error response from API
   * @param error The error object
   * @param customMessages Custom messages to be displayed
   */
  public handleError(
    error: unknown,
    customMessages: {
      forbidden?: string;
      badRequest?: string;
    } = {}
  ) {
    /*
      Handle HttpErrorResponse with exception
    */
    if (error instanceof HttpErrorResponse && error.error?.exception) {
      this.handleException(error.error);
      return;
    }

    /*
      Handle HttpErrorResponse with validation errors
    */
    if (
      error instanceof HttpErrorResponse &&
      error.error.title === 'Invalid.Data'
    ) {
      this.handleValidations(error.error);
      return;
    }

    /*
      Handle HttpErrorResponse with domain errors
    */
    if (error instanceof HttpErrorResponse) {
      this.handleErrors(error.error, customMessages);
      return;
    }

    // This should not happen, but just in case
    this.alertsService.addError(
      'Alerts.Unknown.Title',
      'Alerts.Unknown.Description',
      true
    );
    if (!environment.production) {
      console.error('UnknownError: ', error);
    }
  }

  /**
   * Handle domain errors, these come as a dictionary<string, string>.
   * @param error The error object
   * @param customMessages Custom messages to be displayed
   */
  private handleErrors(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any,
    customMessages: {
      forbidden?: string;
      badRequest?: string;
    } = {}
  ) {
    let title = '';
    let detail = '';
    let onlyOne = true;
    let translate = true;
    let closable = false;
    let sticky = false;

    if (error.errors) {
      // Handle Multiple errors
      title = this.translateService.instant(`Alerts.${error.title}`); // Multiple.Errors
      translate = false;
      closable = true;
      sticky = true;
      onlyOne = false;

      const errorMessages: string[] = [];
      for (const errorCode in error.errors) {
        const errorTitle = this.translateService.instant(`Alerts.${errorCode}`);
        const errorDescription = this.translateService.instant(
          `Alerts.${errorCode}Description`
        );

        const message = `${errorTitle}: ${errorDescription}`;
        errorMessages.push(message);
      }
      detail = errorMessages.join('\n');
    } else {
      title = `Alerts.${error.title}`;
      detail = `Alerts.${error.title}Description`;
    }

    if (
      error.status === HttpStatusCode.BadRequest ||
      error.status === HttpStatusCode.Conflict ||
      error.status === HttpStatusCode.NotFound
    ) {
      title = onlyOne ? customMessages.badRequest || title : title;
      this.alertsService.addWarning(
        title,
        detail,
        translate,
        closable,
        undefined,
        sticky
      );
    } else if (error.status === HttpStatusCode.Forbidden) {
      title = onlyOne ? customMessages.forbidden || title : title;
      this.alertsService.addWarning(
        title,
        detail,
        translate,
        closable,
        undefined,
        sticky
      );
    } else {
      this.alertsService.addError(
        title,
        detail,
        translate,
        closable,
        undefined,
        sticky
      );
    }
  }

  /**
   * Handle exceptions, just translating the title and leave detail as it is.
   * If it contains inner exception (ideally only in development), log it.
   * @param exception
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleException(exception: any) {
    const title = `Alerts.${exception.title}`;
    const detail = exception.detail;

    if (
      exception.status === HttpStatusCode.BadRequest ||
      exception.status === HttpStatusCode.Conflict ||
      exception.status === HttpStatusCode.NotFound ||
      exception.status === HttpStatusCode.Forbidden
    ) {
      this.alertsService.addWarning(title, detail, true);
    } else
      this.alertsService.addError(
        title,
        'Alerts.Exception.UnhandledDescription',
        true
      );

    if (!environment.production) {
      console.error('Exception + InnerException: ', exception);

      if (exception.innerException)
        this.alertsService.addError(
          'Alerts.Exception.InnerException',
          exception.innerException,
          true,
          true,
          undefined,
          true
        );
    }
  }

  /**
   * Handle validations, these come as a dictionary<string, string[]>.
   * @param error
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleValidations(error: any) {
    const title = `Alerts.${error.title}`;
    let detail = `Alerts.Invalid.Description`;
    const failures = error.errors;
    const errorMessages: string[] = [];
    if (failures && typeof failures === 'object' && !Array.isArray(failures)) {
      for (const property in failures) {
        const propertyTranslated = this.translateService.instant(
          `Alerts.${property}.Property`
        );
        const errorsTexts: string[] = failures[property].map(
          (error: string) => {
            if (error.includes('|')) {
              const [errorKey, errorValue] = error.split('|');
              return this.translateService.instant(
                `Alerts.${property}.${errorKey}`,
                [errorValue]
              );
            }
            return this.translateService.instant(`Alerts.${property}.${error}`);
          }
        );

        const errorsText = `${propertyTranslated}: ${errorsTexts.join(', ')}`;
        errorMessages.push(errorsText);
      }
      detail = errorMessages.join('\n');
    }

    this.alertsService.addWarning(title, detail, true, true, undefined, true);
  }
}
