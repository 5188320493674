import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  EditFinancialAdvisorCommand,
  FinancialAdvisorDetailsDto,
  UserLogin
} from '@app/data/amn-api/models';
import { FinancialAdvisorService } from '@app/data/amn-api/services';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TabViewModule } from 'primeng/tabview';
import { SharedModule } from '@app/shared/shared.module';
import { first } from 'rxjs';
import * as dayjs from 'dayjs';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertsService } from '@app/core/services';
import { EditCustomerComponent } from '@app/modules/customers/components/edit-customer/edit-customer.component';
import { CustomersInvestmentsComponent } from '@app/modules/customers-investments/pages/customers-investments/customers-investments.component';
import { FinancialAdvisorCustomService } from '@app/shared/custom-services/financial-advisor/financial-advisor-custom.service';
import { DocumentsListComponent } from '@app/shared/components/documents-list/documents-list.component';
import { FinancialAdvisorDocumentUploadComponent } from '../../financial-advisor-document-upload/financial-advisor-document-upload.component';
import { DocumentManagementService } from '@app/core/services/document.service';

@Component({
  selector: 'app-financial-advisors-details',
  standalone: true,
  imports: [
    PrimeNgModule,
    SharedModule,
    TabViewModule,
    EditCustomerComponent,
    CustomersInvestmentsComponent,
    DocumentsListComponent,
    FinancialAdvisorDocumentUploadComponent
  ],
  templateUrl: './financial-advisors-details.component.html',
  styleUrl: './financial-advisors-details.component.scss'
})
export class FinancialAdvisorsDetailsComponent implements OnInit {
  financialAdvisorId!: number;
  customerName: string = ' ';
  customer!: FinancialAdvisorDetailsDto;
  customerId = 0;
  userLogins!: UserLogin[];
  lastLoginDate: string = '';
  commissionDetails: EditFinancialAdvisorCommand = {
    commission: 0,
    marketing: 0,
    officeId: 0
  };

  private activatedRoute = inject(ActivatedRoute);
  private alertsService = inject(AlertsService);
  private financialAdvisorService = inject(FinancialAdvisorService);
  private financialAdvisorCustomService = inject(FinancialAdvisorCustomService);
  private documentManagementService = inject(DocumentManagementService);

  ngOnInit(): void {
    dayjs.extend(localizedFormat);
    this.financialAdvisorId =
      this.activatedRoute.snapshot.params['financialAdvisorId'];
    this.loadFinancialAdvisor(this.financialAdvisorId);
  }

  onDocumentsUploaded() {
    this.loadFinancialAdvisor(this.financialAdvisorId);
  }

  loadFinancialAdvisor(financialAdvisorId: number) {
    setTimeout(() => {
      this.financialAdvisorService
        .getFinancialAdvisor$Response({ id: financialAdvisorId })
        .pipe(first())
        .subscribe({
          next: (response) => {
            if (response.body.data) {
              let userLoginIndex = 0;
              let userLoginData!: UserLogin;
              this.customer = response.body.data;
              this.customerId = response.body.data.userId ?? 0;
              this.customer.fullName;
              this.customerName = `${this.customer.fullName}`;
              this.commissionDetails.commission = this.customer.commission ?? 0;
              this.commissionDetails.marketing = this.customer.marketing ?? 0;
              this.commissionDetails.officeId =
                Number(this.customer.officeId) ?? 0;
              this.financialAdvisorCustomService.setFinancialAdvisorDetails(
                this.commissionDetails
              );
              this.userLogins = this.customer.userLogins ?? [];
              if (this.userLogins.length) {
                userLoginIndex = Math.max(
                  ...this.userLogins.map((o) => o.id ?? 0)
                );
                userLoginData = this.userLogins.filter(
                  (u) => u.id === userLoginIndex
                )[0];
                this.lastLoginDate = dayjs(userLoginData.createdDate).format(
                  'L LT'
                );
              }
            }
          },
          error: (error: unknown) => {
            if (error instanceof HttpErrorResponse) {
              console.warn('Error getting users: ', error);
            } else {
              console.error('Unknown error', error);
            }
          }
        });
    }, 100);
  }

  deleteDocumentService(documentId: number) {
    this.documentManagementService.deleteDocumentById(documentId, () => {
      this.loadFinancialAdvisor(this.financialAdvisorId);
    });
  }

  downloadDocumentService(documentId: number, fileName?: string) {
    this.documentManagementService.downloadDocumentById(documentId, fileName);
  }

  handleNameChanged(fullName: string) {
    this.customerName = fullName;
  }
}
