import {
  Component,
  EventEmitter,
  Output,
  ViewChild,
  Input,
  inject
} from '@angular/core';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SettingsFormComponent } from '../settings-form/settings-form.component';

@Component({
  standalone: true,
  selector: 'app-modal-settings',
  imports: [PrimeNgModule, SettingsFormComponent, TranslateModule],
  templateUrl: './modal-settings.component.html',
  styleUrls: ['./modal-settings.component.scss']
})
export class ModalSettingsComponent {
  @Input() settingId!: 0;
  visible: boolean = false;
  titleToAdd!: string;
  titleToEdit!: string;

  @Output() cancelSettings = new EventEmitter<void>();
  @Output() addSettings = new EventEmitter<void>();

  @ViewChild(SettingsFormComponent)
  editSettingComponent!: SettingsFormComponent;
  private translateService = inject(TranslateService);

  onAddClick(): void {
    this.titleToAdd = this.translateService.instant('Settings.AddSetting');
    this.titleToEdit = this.translateService.instant('Settings.EditSetting');
    this.visible = true;
    if (this.settingId) {
      this.editSettingComponent.loadSettingsDetails(this.settingId);
    }
    this.editSettingComponent.cleanForm();
  }

  onCancelClick(): void {
    this.visible = false;
    this.cancelSettings.emit();
  }

  onSubmit(): void {
    this.visible = false;
    this.addSettings.emit();
  }
}
