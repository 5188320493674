/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { first } from 'rxjs';
import { PrimeNgModule } from '@app/shared/primeng/primeng.module';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { AlertsService } from '@app/core/services/alerts.service';
import { FilterMatchMode } from 'primeng/api';
import { TranslateModule } from '@ngx-translate/core';
import { UserDto, UserDtoSearchResponse } from '@app/data/amn-api/models';
import { UserService } from '@app/data/amn-api/services';
import { AddCustomerComponent } from '@app/modules/customers/components/add-customer/add-customer.component';
import { DropdownModule } from 'primeng/dropdown';
import { AddUserComponent } from '../../components/add-user/add-user.component';
import { AddUserFormComponent } from '../../components/add-user-form/add-user-form.component';
import { ErrorService } from '@app/core/services/error.service';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    PrimeNgModule,
    TranslateModule,
    AddCustomerComponent,
    DropdownModule,
    AddUserComponent,
    AddUserFormComponent
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss'
})
export class UsersComponent implements OnInit {
  @ViewChild('dt') table!: Table;

  userService = inject(UserService);
  alertsService = inject(AlertsService);
  errorService = inject(ErrorService);

  showCustomers = false;
  showInactives = false;
  totalRecords!: number;
  users: UserDto[] = [];
  userToEdit!: UserDto;
  visible = false;
  random = Math.random();

  searchCriteria = '';
  top: number = 10;
  skip: number = 0;
  sortBy: string = '';
  sortDesc = false;

  ngOnInit(): void {
    this.loadUsers();
  }

  onLazyLoad(event: TableLazyLoadEvent) {
    if (event.sortField !== undefined || event.globalFilter !== undefined) {
      this.top = event.rows ?? this.top;
      this.skip = event.first ?? this.skip;
      this.searchCriteria =
        (event.globalFilter as string) ?? this.searchCriteria;
      this.sortBy = (event.sortField as string) ?? this.sortBy;
      this.sortDesc = event.sortOrder === -1;

      localStorage.setItem(
        'usersFilter',
        JSON.stringify({
          top: this.top,
          skip: this.skip,
          searchCriteria: this.searchCriteria,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          showCustomers: this.showCustomers,
          showInactives: this.showInactives
        })
      );
      this.loadUsers();
    }
  }

  loadUsers(): void {
    this.userService
      .searchUsersAsync({
        SearchText: this.searchCriteria,
        Top: this.top,
        Skip: this.skip,
        SortBy: this.sortBy,
        SortDescending: this.sortDesc,
        ShowCustomers: this.showCustomers,
        ShowInactives: this.showInactives
      })
      .pipe(first())
      .subscribe({
        next: (value: UserDtoSearchResponse) => {
          if (!value.data) {
            this.alertsService.addWarning(
              'Alerts.CustomersNotFound',
              'Alerts.CustomersNotFoundDesc',
              true
            );
          } else {
            this.users = value.data;
            this.totalRecords = value.totalRecords ?? 0;
          }
        },
        error: (error: unknown) => {
          this.errorService.handleError(error);
        }
      });
  }

  onGlobalFilter(table: Table, event: Event) {
    const searchTerm = (event.target as HTMLInputElement).value as string;
    this.getSearchTermsUsers(searchTerm);
    table.filterGlobal(this.searchCriteria, FilterMatchMode.CONTAINS);
  }

  getSearchTermsUsers(searchTerm: string | null) {
    if (!!searchTerm && searchTerm !== '') {
      this.searchCriteria = searchTerm;
      localStorage.setItem('searchTermsUser', this.searchCriteria);
    } else {
      this.searchCriteria = '';
      localStorage.removeItem('searchTermsUser');
    }
  }

  onAddUser(): void {
    this.loadUsers();
  }

  showDialogToEdit(user: UserDto) {
    this.random = Math.random();
    this.userToEdit = user;
    return (this.visible = true);
  }

  handleOnShowParentModal($event: boolean) {
    this.visible = $event;
  }

  onIsActiveChange($event: any) {
    this.showCustomers = $event.checked;
    this.loadUsers();
  }

  onShowInactivesUsersChange($event: any) {
    this.showInactives = $event.checked;
    this.loadUsers();
  }
}
