/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TotalInvestmentsDtoApiResponse } from '../../models/total-investments-dto-api-response';

export interface SearchCustomersInvestments$Params {
  SearchText?: string;
  Top?: number;
  Skip?: number;
  SortBy?: string;
  SortDescending?: boolean;
  FinancialAdvisorId?: number;
}

export function searchCustomersInvestments(http: HttpClient, rootUrl: string, params?: SearchCustomersInvestments$Params, context?: HttpContext): Observable<StrictHttpResponse<TotalInvestmentsDtoApiResponse>> {
  const rb = new RequestBuilder(rootUrl, searchCustomersInvestments.PATH, 'get');
  if (params) {
    rb.query('SearchText', params.SearchText, {});
    rb.query('Top', params.Top, {});
    rb.query('Skip', params.Skip, {});
    rb.query('SortBy', params.SortBy, {});
    rb.query('SortDescending', params.SortDescending, {});
    rb.query('FinancialAdvisorId', params.FinancialAdvisorId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TotalInvestmentsDtoApiResponse>;
    })
  );
}

searchCustomersInvestments.PATH = '/api/report/customers-investments';
