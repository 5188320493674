/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { importMyFxBookAccountsAsync } from '../fn/my-fx-book/import-my-fx-book-accounts-async';
import { ImportMyFxBookAccountsAsync$Params } from '../fn/my-fx-book/import-my-fx-book-accounts-async';
import { importMyFxBookAccountsAsync$Plain } from '../fn/my-fx-book/import-my-fx-book-accounts-async-plain';
import { ImportMyFxBookAccountsAsync$Plain$Params } from '../fn/my-fx-book/import-my-fx-book-accounts-async-plain';
import { importMyFxBookDailyGainsAsync } from '../fn/my-fx-book/import-my-fx-book-daily-gains-async';
import { ImportMyFxBookDailyGainsAsync$Params } from '../fn/my-fx-book/import-my-fx-book-daily-gains-async';
import { importMyFxBookDailyGainsAsync$Plain } from '../fn/my-fx-book/import-my-fx-book-daily-gains-async-plain';
import { ImportMyFxBookDailyGainsAsync$Plain$Params } from '../fn/my-fx-book/import-my-fx-book-daily-gains-async-plain';
import { MyFxBookAccountDtoSearchResponse } from '../models/my-fx-book-account-dto-search-response';
import { MyFxBookDailyGainsGroupedByAccountDtoSearchResponse } from '../models/my-fx-book-daily-gains-grouped-by-account-dto-search-response';
import { searchMyFxBookAccounts } from '../fn/my-fx-book/search-my-fx-book-accounts';
import { SearchMyFxBookAccounts$Params } from '../fn/my-fx-book/search-my-fx-book-accounts';
import { searchMyFxBookAccounts$Plain } from '../fn/my-fx-book/search-my-fx-book-accounts-plain';
import { SearchMyFxBookAccounts$Plain$Params } from '../fn/my-fx-book/search-my-fx-book-accounts-plain';
import { searchMyFxBookDailyGains } from '../fn/my-fx-book/search-my-fx-book-daily-gains';
import { SearchMyFxBookDailyGains$Params } from '../fn/my-fx-book/search-my-fx-book-daily-gains';
import { searchMyFxBookDailyGains$Plain } from '../fn/my-fx-book/search-my-fx-book-daily-gains-plain';
import { SearchMyFxBookDailyGains$Plain$Params } from '../fn/my-fx-book/search-my-fx-book-daily-gains-plain';
import { SuccessApiResponse } from '../models/success-api-response';

@Injectable({ providedIn: 'root' })
export class MyFxBookService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `searchMyFxBookAccounts()` */
  static readonly SearchMyFxBookAccountsPath = '/api/my-fx-book/accounts';

  /**
   * Search MyFxBook Accounts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchMyFxBookAccounts$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchMyFxBookAccounts$Plain$Response(params?: SearchMyFxBookAccounts$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MyFxBookAccountDtoSearchResponse>> {
    return searchMyFxBookAccounts$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search MyFxBook Accounts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchMyFxBookAccounts$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchMyFxBookAccounts$Plain(params?: SearchMyFxBookAccounts$Plain$Params, context?: HttpContext): Observable<MyFxBookAccountDtoSearchResponse> {
    return this.searchMyFxBookAccounts$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MyFxBookAccountDtoSearchResponse>): MyFxBookAccountDtoSearchResponse => r.body)
    );
  }

  /**
   * Search MyFxBook Accounts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchMyFxBookAccounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchMyFxBookAccounts$Response(params?: SearchMyFxBookAccounts$Params, context?: HttpContext): Observable<StrictHttpResponse<MyFxBookAccountDtoSearchResponse>> {
    return searchMyFxBookAccounts(this.http, this.rootUrl, params, context);
  }

  /**
   * Search MyFxBook Accounts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchMyFxBookAccounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchMyFxBookAccounts(params?: SearchMyFxBookAccounts$Params, context?: HttpContext): Observable<MyFxBookAccountDtoSearchResponse> {
    return this.searchMyFxBookAccounts$Response(params, context).pipe(
      map((r: StrictHttpResponse<MyFxBookAccountDtoSearchResponse>): MyFxBookAccountDtoSearchResponse => r.body)
    );
  }

  /** Path part for operation `importMyFxBookAccountsAsync()` */
  static readonly ImportMyFxBookAccountsAsyncPath = '/api/my-fx-book/accounts';

  /**
   * Import MyFxBook accounts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importMyFxBookAccountsAsync$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  importMyFxBookAccountsAsync$Plain$Response(params?: ImportMyFxBookAccountsAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return importMyFxBookAccountsAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Import MyFxBook accounts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importMyFxBookAccountsAsync$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  importMyFxBookAccountsAsync$Plain(params?: ImportMyFxBookAccountsAsync$Plain$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.importMyFxBookAccountsAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

  /**
   * Import MyFxBook accounts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importMyFxBookAccountsAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  importMyFxBookAccountsAsync$Response(params?: ImportMyFxBookAccountsAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return importMyFxBookAccountsAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Import MyFxBook accounts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importMyFxBookAccountsAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  importMyFxBookAccountsAsync(params?: ImportMyFxBookAccountsAsync$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.importMyFxBookAccountsAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

  /** Path part for operation `searchMyFxBookDailyGains()` */
  static readonly SearchMyFxBookDailyGainsPath = '/api/my-fx-book/daily-gains';

  /**
   * Search MyFxBook daily gains by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchMyFxBookDailyGains$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchMyFxBookDailyGains$Plain$Response(params?: SearchMyFxBookDailyGains$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<MyFxBookDailyGainsGroupedByAccountDtoSearchResponse>> {
    return searchMyFxBookDailyGains$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Search MyFxBook daily gains by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchMyFxBookDailyGains$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchMyFxBookDailyGains$Plain(params?: SearchMyFxBookDailyGains$Plain$Params, context?: HttpContext): Observable<MyFxBookDailyGainsGroupedByAccountDtoSearchResponse> {
    return this.searchMyFxBookDailyGains$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MyFxBookDailyGainsGroupedByAccountDtoSearchResponse>): MyFxBookDailyGainsGroupedByAccountDtoSearchResponse => r.body)
    );
  }

  /**
   * Search MyFxBook daily gains by search criteria.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchMyFxBookDailyGains()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchMyFxBookDailyGains$Response(params?: SearchMyFxBookDailyGains$Params, context?: HttpContext): Observable<StrictHttpResponse<MyFxBookDailyGainsGroupedByAccountDtoSearchResponse>> {
    return searchMyFxBookDailyGains(this.http, this.rootUrl, params, context);
  }

  /**
   * Search MyFxBook daily gains by search criteria.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchMyFxBookDailyGains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchMyFxBookDailyGains(params?: SearchMyFxBookDailyGains$Params, context?: HttpContext): Observable<MyFxBookDailyGainsGroupedByAccountDtoSearchResponse> {
    return this.searchMyFxBookDailyGains$Response(params, context).pipe(
      map((r: StrictHttpResponse<MyFxBookDailyGainsGroupedByAccountDtoSearchResponse>): MyFxBookDailyGainsGroupedByAccountDtoSearchResponse => r.body)
    );
  }

  /** Path part for operation `importMyFxBookDailyGainsAsync()` */
  static readonly ImportMyFxBookDailyGainsAsyncPath = '/api/my-fx-book/daily-gains';

  /**
   * Import MyFxBook daily gains.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importMyFxBookDailyGainsAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  importMyFxBookDailyGainsAsync$Plain$Response(params?: ImportMyFxBookDailyGainsAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return importMyFxBookDailyGainsAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Import MyFxBook daily gains.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importMyFxBookDailyGainsAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  importMyFxBookDailyGainsAsync$Plain(params?: ImportMyFxBookDailyGainsAsync$Plain$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.importMyFxBookDailyGainsAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

  /**
   * Import MyFxBook daily gains.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importMyFxBookDailyGainsAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  importMyFxBookDailyGainsAsync$Response(params?: ImportMyFxBookDailyGainsAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<SuccessApiResponse>> {
    return importMyFxBookDailyGainsAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Import MyFxBook daily gains.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importMyFxBookDailyGainsAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  importMyFxBookDailyGainsAsync(params?: ImportMyFxBookDailyGainsAsync$Params, context?: HttpContext): Observable<SuccessApiResponse> {
    return this.importMyFxBookDailyGainsAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessApiResponse>): SuccessApiResponse => r.body)
    );
  }

}
