@if (beneficiaryId) {
  <p-button
    icon="pi pi-pencil"
    (click)="onEditClick()"
    [rounded]="true"
    [text]="true"
    pTooltip="{{ 'Common.Edit' | translate }}"
    tooltipPosition="left"></p-button>
} @else {
  <p-button
    (click)="onAddClick()"
    label="{{ 'Common.AddNew' | translate }}"
    icon="pi pi-plus"
    [outlined]="true"></p-button>
}
<p-dialog
  [header]="beneficiaryId ? titleToEdit : titleToAdd"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '40vw' }"
  [draggable]="false"
  [resizable]="false">
  <app-customer-beneficiary-form
    [beneficiaryId]="beneficiaryId"
    [customerId]="customerId"
    (submitBeneficiary)="onSubmit()"
    (cancelBeneficiary)="onCancelClick()"></app-customer-beneficiary-form>
</p-dialog>
