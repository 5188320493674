/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createCustomerBeneficiariesAsync } from '../fn/customer-beneficiaries/create-customer-beneficiaries-async';
import { CreateCustomerBeneficiariesAsync$Params } from '../fn/customer-beneficiaries/create-customer-beneficiaries-async';
import { createCustomerBeneficiariesAsync$Plain } from '../fn/customer-beneficiaries/create-customer-beneficiaries-async-plain';
import { CreateCustomerBeneficiariesAsync$Plain$Params } from '../fn/customer-beneficiaries/create-customer-beneficiaries-async-plain';
import { CustomerBeneficiariesDtoApiResponse } from '../models/customer-beneficiaries-dto-api-response';

@Injectable({ providedIn: 'root' })
export class CustomerBeneficiariesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createCustomerBeneficiariesAsync()` */
  static readonly CreateCustomerBeneficiariesAsyncPath = '/api/customer-beneficiaries/{id}';

  /**
   * Create Customer Beneficiaries.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCustomerBeneficiariesAsync$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCustomerBeneficiariesAsync$Plain$Response(params: CreateCustomerBeneficiariesAsync$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>> {
    return createCustomerBeneficiariesAsync$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Customer Beneficiaries.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCustomerBeneficiariesAsync$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCustomerBeneficiariesAsync$Plain(params: CreateCustomerBeneficiariesAsync$Plain$Params, context?: HttpContext): Observable<CustomerBeneficiariesDtoApiResponse> {
    return this.createCustomerBeneficiariesAsync$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>): CustomerBeneficiariesDtoApiResponse => r.body)
    );
  }

  /**
   * Create Customer Beneficiaries.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCustomerBeneficiariesAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCustomerBeneficiariesAsync$Response(params: CreateCustomerBeneficiariesAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>> {
    return createCustomerBeneficiariesAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Customer Beneficiaries.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCustomerBeneficiariesAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCustomerBeneficiariesAsync(params: CreateCustomerBeneficiariesAsync$Params, context?: HttpContext): Observable<CustomerBeneficiariesDtoApiResponse> {
    return this.createCustomerBeneficiariesAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomerBeneficiariesDtoApiResponse>): CustomerBeneficiariesDtoApiResponse => r.body)
    );
  }

}
